@import '../App.scss';

.service-page {
    .hero-section {
        width: 100%;
        min-height: 700px;
        padding: 0px;
        background-image: url(../Images/products-page/product-hero-image.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        animation: PartialFadeIn 500ms ease-in-out 0.2s 1 normal forwards;
        opacity: 0.5;
        z-index: 1;

        .hero-content {
            padding: 0px 140px;
            color: #FFFFFF;
            padding-top: 160px;
            width: 68%;
    
     
            h1 {
                width: 90%;
                margin: 20px 0px;
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.4s 1 normal forwards;

    
                span {
                 color: #ED4622;
                }
            }
    
            .description {
                width: 75%;
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 0.8s 1 normal forwards;

            }

            .learn-more-box {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 1.2s 1 normal forwards;

                .subscription-button {
                    margin-left: 0px;
                    outline: 1px solid;
                    outline-color: rgba(255, 255, 255, .5);
                    outline-offset: 0px;
                    text-shadow: none;
                    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0), 0 2px 20px 4px #ffffff44;;
                    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

                    &:hover {
                        box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2), 0 2px 20px 4px #ffffff44;
                        outline-color: rgba(255, 255, 255, 0);
                        outline-offset: 15px;
                        text-shadow: 1px 1px 2px #427388; 
                    }
                }
            }
        }

        .wave {
            position: relative;
            display: block;
            transform: translateY(5px);
            z-index: 1;

        }
    }

    .services-section {
        padding: 50px 0px 50px 0px;
        text-align: center;
        background-color: #FFFFFF;
    
        h1 {
            width: 70%;
            margin: 0 auto;
            color: #121E52;
            padding:  0px 140px ;
            opacity: 0;
        }
    
        .description {
            width: 70%;
            margin: 27px auto 0px auto;
            padding: 28px 0px 20px 0px;
            opacity: 0;

        }

        .service-image-wrapper {
            width: 100%;
            opacity: 0;

            img {
                width: 100%;
            }
        }

        &.animate-services {
            h1 {
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.3s 1 normal forwards;
            }
        
            .description {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 0.5s 1 normal forwards;
            }
    
            .service-image-wrapper {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 0.7s 1 normal forwards;

            }
        }
    }

    .why-us-section {
        padding: 30px 0px 30px 0px;
        background-color: #FFFFFF;

        .block {
            display: flex;
            padding: 65px 0px;
            align-items: center;
            
            h1 {
                color: #121E52;
                font-size: 42px;
            }
            &.animate-1, &.animate-2 {
                h1 {
                    opacity: 0;
                    animation: FadeInDownUp 500ms ease-in-out 0.3s 1 normal forwards; 
                }
                p{
                    opacity: 0;
                    animation: RegularFadeIn 500ms ease-in-out 0.5s 1 normal forwards;
                }

                .image-wrapper {
                    opacity: 0;
                    animation: SlideIn 1000ms ease-in-out 0.7s 1 normal forwards, RegularFadeIn 500ms ease-in-out 0.9s 1 normal forwards;
                }
            }
            
            
        }
    
        #block1 {
            padding-left: 140px;
    
            .content {
                width: 40%;
                span {
                    color: #4C6FFC;
                }
            }
    
            .image-wrapper {
                width: 60%;
                transform: translateX(150%);
    
                img {
                    width: 150%;
                }
            }
    
        }
    
        #block2 {
            padding-right: 140px;
    
            .content {
                width: 40%;
                margin-left: 100px;
                span {
                    color: #ED4622;
                }
            }
    
            .image-wrapper {
                width: 60%;
                margin-right: 100px;
                margin-left: -120px;
                transform: translateX(-150%);

    
                img {
                    width: 120%;
                }
            }
    
        }
    }

    .devices-section {
        padding: 150px 0px 50px 0px;
        text-align: center;
        background-color: #F6F6F6;
    
        h1 {
            width: 70%;
            margin: 0 auto;
            color: #121E52;
            padding:  0px 140px ;
    
    
            span {
                color: #ED4622;
            }
        }
    
        .description {
            width: 70%;
            margin: 27px auto 0px auto;
        }

        .device-box-wrapper {

            background: url(../Images/products-page/hardware-background.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding: 50px 0px 75px 0px;
            .device-box {
                display: flex;
                gap: 45px;
                padding: 10px 0px 40px 0px;
                background-color: transparent;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin: 0 auto;

                .device-card {
                    background-color: #FFFFFF;
                    box-shadow: 0px 2px 20px 0px rgb(0,0,0,0.2);
                    padding: 35px 30px 0px 30px;
                    border-radius: 10px;
                    overflow: hidden;
                    position: relative;
                    height: 450px;
                    width: 350px;


                    .purpose {
                        font-size: 12px;
                        text-align: start;
                        padding: 0px;
                        margin: 0px;    
                    }

                    .name {
                        font-size: 26px;
                        text-align: start;
                        padding: 0px;
                        margin: 10px 0px;
                        line-height: 120%;
                        width: 100%;
                        color: #4C6FFC
                    }

                    .description {
                        font-size: 14px;
                        text-align: start;
                        padding: 0px;
                        margin: 0px;
                        width:80%;
                        line-height: 150%;
                    }

                    .device {
                        width: 300px;
                        right: -60px;
                        bottom: -60px;
                        position: absolute;

                    }
                }

                #raman {
                    .device {
                        right: -70px;
                        bottom: -100px;
                    }
                }

                #menlo {
                    .device {
                        right: -80px;
                        bottom: -50px;
                        width: 400px;

                    }
                }

                #fibre {
                    .device {
                        right: -120px;
                        bottom: -20px;
                        width: 400px;

                    }
                }

                #paint-thickness {
                    .device {
                        right: -50px;
                        bottom: -80px;
                    }
                }
            }   
        }

        &.animate-device {
            h1 {
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.3s 1 normal forwards;
            }
        
            .description {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 0.5s 1 normal forwards;
            }
        }
    }

    .subscription-section {
        padding: 120px 0px 50px 0px;
        text-align: center;
        background-color: #FFFFFF;
    
        h1 {
            width: 70%;
            margin: 0 auto;
            color: #121E52;
            padding:  0px 140px ;
    
        }
    
        .description {
            width: 70%;
            margin: 27px auto 0px auto;
            padding: 28px 0px 20px 0px;
        }

        .subscription-block {
            display: flex;
            flex-direction: row;
            padding:  80px 140px ;
            align-items: center;
            justify-content: center;
            gap: 10px;
            

            .block-number {
                padding: 20px;
                width: 300px;
                
                div {
                    display: block;
                    border: 6px solid #4C6FFC;
                    color:#4C6FFC;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 100px;
                    font-family: 'Poppins';
                    font-weight: 600;
                    border-radius: 50%;
                    font-size: 48px;
                    margin: 0 auto;
                }

                p {
                    text-align: center;
                    margin: 0 auto;
                    width: 80%;
                    font-weight: 500;
                    font-size: 16px;
                }
            }

            .cards {
                display: flex;
                gap: 30px;

                .card {
                    width: 300px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 0px 20px -5px rgb(0,0,0,0.2);
                    padding: 50px 0px 30px 0px;
                    border-radius: 5px;
                    height: fit-content;
                    min-height: 350px ;

                    img {
                        width: 50px;
                        margin-bottom: 20px;
                    }
                    
                    h3 {
                        font-size: 24px;
                        font-weight: 500;
                        font-family: 'Poppins';
                        padding: 0px;
                        margin: 0px;
                        color: #4C6FFC;
                    }

                    p {
                        font-size: 14px;
                        padding: 0px;
                        margin: 20px auto;
                        

                    }

                    ul {
                        font-size: 14px;
                        list-style: none;
                        font-family: 'Lato';

                        li {
                            padding: 20px 0px;
                            margin: 0px 40px;
                            border-top: 2px solid #F6F6F6;
                        }
                    }
                }



            }
        }

        &.animate-subscription {
            h1 {
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.3s 1 normal forwards;
            }
        
            > .description {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 0.5s 1 normal forwards;
            }
        }

    }
}

@media only screen and (max-width: 1200px) {
    .service-page {
        .hero-section {                
            .hero-content {
                padding: 0px 60px;
                padding-top: 160px;
                width: 80%;
        
         
                h1 {
                    width: 75%;
                    margin: 20px 0px;
                }
        
                .description {
                    width: 70%;
                }
            }

            .wave {
                transform: translateY(30px);    
            }
        }
        
        .services-section {
        
            h1 {
                width: 80%;
                padding: 0px 60px ;
            }
        
            .description {
                width: 80%;
            }
        
        }

        .why-us-section {
        
            .block {

                
                h1 {
                    font-size: 40px;
                }

                p {
                    font-size: 16px;
                }
                
            }
        
            #block1 {
                padding-left: 60px;
        
                .content {
                    width: 45%;

                }
        
                .image-wrapper {
                    width: 55%;
        
                    img {
                        width: 150%;
                    }
                }
        
            }
        
            #block2 {
                padding-right: 60px;
        
                .content {
                    width: 45%;
                    margin-left: 100px;

                }
        
                .image-wrapper {
                    width: 55%;
                    margin-right: 100px;
                    margin-left: -120px;
        
                    img {
                        width: 120%;
                    }
                }
            }
        }
        
        .devices-section {        
            h1 {
                width: 80%;
                padding:  0px 60px ;
            }
        
            .description {
                width: 80%;
            }
    
            .device-box-wrapper {
    
                .device-box {
                    gap: 30px;
    
                    .device-card {
                        padding: 35px 30px 0px 30px;
                        border-radius: 10px;
                        overflow: hidden;
                        position: relative;
                        height: 400px;
                        width: 300px;
    
    
                        .purpose {
                            font-size: 12px;
                
                        }
    
                        .name {
                            font-size: 22px;
                        }
    
                        .description {
                            font-size: 14px;
                            width: 100%;
                        }
    
                        .device {
                            width: 250px;
                            right: -60px;
                            bottom: -60px;
                            position: absolute;
    
                        }
                    }

    
                    #menlo {
                        .device {
                            width: 350px;
                        }
                    }
    
                    #fibre {
                        .device {
                            width: 350px;
                        }
                    }
                }   
            }
        }
        .subscription-section {    
            h1 {
                width: 80%;
                padding: 0px 60px ;
            }
        
            .description {
                width: 80%;
            }
    
            .subscription-block {
                padding:  80px 60px ;
    
                .block-number {
                    padding: 20px;
                    width: 300px;
                    
                    div {
                        border: 4px solid #4C6FFC;
                        width: 80px;
                        height: 80px;
                        font-size: 44px;
                    }
    
                    p {
                        font-size: 14px;
                    }
                }
    
                .cards {
                    gap: 20px;
    
                    .card {
                        width: 250px;
    
                        img {
                            width: 40px;
                        }
                        
                        h3 {
                            font-size: 22px;

                        }
    
                        p {
                            font-size: 12px;
                        }
    
                        ul {
                            font-size: 12px;

                        }
                    }
    
    
    
                }
            }
    
        }
    }
}

@media only screen and (max-width: 992px) {
    .service-page {
        .hero-section {     
            background-position: -150px 0px;           
            .hero-content {
                padding: 0px 50px;
                padding-top: 160px;
                width: 90%;
        
         
                h1 {
                    width: 75%;
                    margin: 20px 0px;
                }
        
                .description {
                    width: 70%;
                }
            }

            .wave {
                transform: translateY(100px);    
            }
        }
        
        .services-section {
        
            h1 {
                width: 90%;
                padding: 0px 50px ;
            }
        
            .description {
                width: 80%;
            }
        
        }

        .why-us-section {
        
            .block {

                
                h1 {
                    font-size: 30px;
                }

                p {
                    font-size: 14px;
                }
                
            }
        
            #block1 {
                padding-left: 50px;
        
                .content {
                    width: 50%;

                }
        
                .image-wrapper {
                    width: 50%;
        
                    img {
                        width: 150%;
                    }
                }
        
            }
        
            #block2 {
                padding-right: 50px;
        
                .content {
                    width: 50%;
                    margin-left: 100px;

                }
        
                .image-wrapper {
                    width: 50%;
                    margin-right: 40px;
                    margin-left: -50px;
        
                    img {
                        width: 120%;
                    }
                }
            }
        }
        
        .devices-section {        
            h1 {
                width: 80%;
                padding:  0px 50px ;
            }
        
            .description {
                width: 80%;
            }
    
            .device-box-wrapper {
    
                .device-box {
                    gap: 20px;
    
                    .device-card {
                        padding: 35px 25px 0px 25px;
                        border-radius: 10px;
                        height: 350px;
                        width: 250px;
    
    
                        .purpose {
                            font-size: 12px;
                
                        }
    
                        .name {
                            font-size: 18px;
                        }
    
                        .description {
                            font-size: 14px;
                            width: 100%;
                        }
    
                        .device {
                            width: 200px;
                            right: -40px;
                            bottom: -40px;
                            position: absolute;
    
                        }
                    }

                    #raman {
                        .device {
                            right: -50px;
                            bottom: -80px;
                        }
                    }

                    #menlo {
                        .device {
                            width: 300px;
                        }
                    }
    
                    #fibre {
                        .device {
                            width: 300px;
                        }
                    }

                    #paint-thickness {
                        .device {
                            right: -30px;
                            bottom: -60px;
                        }
                    }
                }   
            }
        }
        .subscription-section {    
            h1 {
                width: 80%;
                padding: 0px 50px ;
            }
        
            .description {
                width: 80%;
            }
    
            .subscription-block {
                display: block;
                padding:  80px 50px ;
    
                .block-number {
                    padding: 0px;
                    width: 100%;
                    
                    div {
                        border: 4px solid #4C6FFC;
                        width: 100px;
                        height: 100px;
                        font-size: 48px;
                    }
    
                    p {
                        font-size: 14px;
                    }
                }
    
                .cards {
                    gap: 20px;
                    width: 100%;
    
                    .card {
                        flex-grow: 1;

                    }
    
    
    
                }
            }
    
        }
    }
}


@media only screen and (max-width: 768px) {
    .service-page {
        .hero-section {     
            background-position: -300px 0px;           
            .hero-content {
                padding: 0px 30px;
                padding-top: 160px;
                width: 100%;
        
                h1 {
                    width: 80%;
                    margin: 20px 0px;
                }
        
                .description {
                    width: 70%;
                }
            }

            .wave {
                transform: translateY(150px);    
            }
        }
        
        .services-section {
        
            h1 {
                width: 90%;
                padding: 0px 30px ;
            }
        
            .description {
                width: 90%;
            }
        
        }

        .why-us-section {
        
            .block {

                
                h1 {
                    font-size: 30px;
                }

                p {
                    font-size: 14px;
                }
                
            }
        
            #block1 {
                padding-left: 0px;
                background-color: #F6F6F6;
        
                .content {
                    width: 100%;
                    margin: 0px 30px;
                    text-align: center;
                }
        
                .image-wrapper {
                    width: 50%;
                    display: none;
        
                    img {
                        width: 150%;
                    }
                }
        
            }
        
            #block2 {
                padding-right: 0px;
        
                .content {
                    width: 100%;
                    margin: 0px 30px;
                    text-align: center;
                }
        
                .image-wrapper {
                    width: 50%;
                    margin-right: 40px;
                    margin-left: -50px;
                    display: none;
        
                    img {
                        width: 120%;
                    }
                }
            }
        }
        
        .devices-section {        
            h1 {
                width: 90%;
                padding:  0px 30px ;
            }
        
            .description {
                width: 90%;
            }
    
            .device-box-wrapper {
    
                .device-box {
                    gap: 20px;
                    padding: 0px 30px;
                    .device-card {
                        padding: 35px 25px 0px 25px;
                        border-radius: 10px;
                        height: 400px;
                        width: 48%;
    
    
                        .purpose {
                            font-size: 12px;
                
                        }
    
                        .name {
                            font-size: 18px;
                        }
    
                        .description {
                            font-size: 14px;
                            width: 100%;
                        }
    
                        .device {
                            width: 250px;
                            right: -40px;
                            bottom: -40px;
                            position: absolute;
    
                        }
                    }

                    #raman {
                        .device {
                            right: -50px;
                            bottom: -80px;
                        }
                    }

                    #menlo {
                        .device {
                            width: 300px;
                        }
                    }
    
                    #fibre {
                        .device {
                            width: 300px;
                        }
                    }

                    #paint-thickness {
                        .device {
                            right: -30px;
                            bottom: -60px;
                        }
                    }
                }   
            }
        }
        .subscription-section {    
            h1 {
                width: 80%;
                padding: 0px 50px ;
            }
        
            .description {
                width: 80%;
            }
    
            .subscription-block {
                display: block;
                padding:  80px 50px ;
    
                .block-number {
                    padding: 0px;
                    width: 100%;
                    
                    div {
                        border: 4px solid #4C6FFC;
                        width: 100px;
                        height: 100px;
                        font-size: 48px;
                    }
    
                    p {
                        font-size: 14px;
                    }
                }
    
                .cards {
                    gap: 20px;
                    width: 100%;
                    display: block;
    
                    .card {
                        width: 100%;
                        margin: 30px 0px;

                        img {
                            width: 70px;
                        }

                        h3 {
                            font-size: 24px;
                        }

                        p, li {
                            font-size: 14px;
                        }

                    }
    
    
    
                }
            }
    
        }
    }
}

@media only screen and (max-width: 576px) {
    .service-page {
        .hero-section {     
            background-position: -450px 0px;           
            .hero-content {
                padding: 0px 30px;
                padding-top: 160px;
                width: 100%;
        
                h1 {
                    width: 100%;
                    margin: 20px 0px;
                }
        
                .description {
                    width: 80%;
                }
                
                button {
                    display: block;

                }
            }

            .wave {
                transform: translateY(80px);    
            }
        }
        
        
        .devices-section {        
            h1 {
                width: 90%;
                padding:  0px 30px ;
            }
        
            .description {
                width: 90%;
            }
    
            .device-box-wrapper {
    
                .device-box {
                    gap: 20px;
                    padding: 0px 30px;
                    .device-card {
                        padding: 35px 25px 0px 25px;
                        border-radius: 10px;
                        height: 400px;
                        width: 100%;
    
    
                        .purpose {
                            font-size: 14px;
                
                        }
    
                        .name {
                            font-size: 22px;
                        }
    
                        .description {
                            font-size: 14px;
                            width: 100%;
                        }
    
                        .device {
                            width: 300px;
                            right: -60px;
                            bottom: -100px;
                            position: absolute;
    
                        }
                    }

                    #raman {
                        .device {
                            right: -50px;
                            bottom: -140px;
                        }
                    }

                    #menlo {
                        .device {
                            width: 400px;
                            bottom: -40px;
                            right: -40px;
                        }
                    }
    
                    #fibre {
                        .device {
                            width: 400px;
                        }
                    }

                    #paint-thickness {
                        .device {
                            right: -30px;
                            bottom: -60px;
                        }
                    }
                }   
            }
        }
        .subscription-section {    
            h1 {
                width: 90%;
                padding: 0px 30px ;
            }
        
            .description {
                width: 80%;
            }
    
            .subscription-block {
                display: block;
                padding:  80px 30px ;
            }
    
        }
    }
}


@media only screen and (max-width: 460px) {
    .service-page {
        .hero-section {    
            .wave {
                transform: translateY(100px);
            }

        }
    }
}

@media only screen and (max-width: 438px) {
    .service-page {
        .hero-section {    
            .wave {
                transform: translateY(10px);
            }

        }
    
    }
}

@media only screen and (max-width: 436px) {
    .service-page {
        .hero-section {    
            .wave {
                transform: translateY(40px);
            }

        }
    
    }
    
}


@media only screen and (max-width: 426px) {
    .service-page {
        .hero-section {    
            .wave {
                transform: translateY(65px);
            }

        }
    }
}

@media only screen and (max-width: 370px) {
    .service-page {
        .hero-section {    
            .wave {
                transform: translateY(40px);
            }

        }
    }
}