@import '../App.scss';
@import '../Animations.scss';

@keyframes waveAnimation {
    to {
      transform: translateY(500px);
    }
}


.hero-page {
    .hero-section {
        width: 100%;
        min-height: 700px;
        padding: 0px;
        background-image: url(../Images/hero-page/hero-image.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        z-index: 1;
        animation: PartialFadeIn 500ms ease-in-out 0.2s 1 normal forwards;
        opacity: 0.5;
        position: relative;
    
        .hero-content {
            padding: 0px 140px;
            color: #FFFFFF;
            padding-top: 160px;
            width: 65%;
            
    
     
            h1 {
                width: 90%;
                margin: 20px 0px;
    
                span {
                 color: #ED4622;
                }
            }

            
            .bottom-h1 {
                margin-top: 5px;
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.3s 1 normal forwards;

            }

            .top-h1 {
                margin-bottom: 10px;
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.1s 1 normal forwards;
            }
    
            .description {
                width: 80%;
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 0.6s 1 normal forwards;
            }

            .contact-box {
                width: 90%;
                .email-input {
                    opacity: 0;
                    width: 10%;
                    animation: FadeExpand 1500ms ease 1.6s 1 normal forwards;
                }

                button {
                    outline: 1px solid;
                    outline-color: rgba(255, 255, 255, .5);
                    outline-offset: 0px;
                    text-shadow: none;
                    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0), 0 2px 20px 4px #ffffff44;;
                    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
                    opacity: 0;
                    animation: RegularFadeIn 500ms ease-in-out 0.8s 1 normal forwards;


                    &:hover {
                        box-shadow: inset 0 0 0px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2), 0 2px 20px 4px #ffffff44;
                        outline-color: rgba(255, 255, 255, 0);
                        outline-offset: 15px;
                        text-shadow: 1px 1px 2px #427388; 
                    }
                }

            }
    
    
        }

        > span {
            position: absolute;
            top: 50%;
            left: 40%;
            width: 4px;
            height: 4px;
            background-color: #FFFFFF;
            transform: translateY(-50%);
            border-radius: 50%;
            box-shadow:  0 0 0 2px rgba(255, 255, 255, .1), 
            0 0 0 4px rgba(255, 255, 255, .1),
            0 0 10px 0 rgba(255, 255, 255, 1) ;
            animation: StarFall 3s linear infinite;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                width: 300px;
                height: 1px;
                background: linear-gradient(90deg, #fff, transparent);
            }

            &:nth-child(1) {
                top: -5px;
                right: 100px;
                left: initial;
                animation-duration: 1.5s;
                animation-delay: 1s;
            }
            &:nth-child(2) {
                top: -5px;
                right: 80px;
                left: initial;
                animation-duration: 5s;
                animation-delay: 0.2s;
            }
            &:nth-child(3) {
                top: 80px;
                right: -5px;
                left: initial;
                animation-duration: 4s;
                animation-delay: 0.4s;
            }
            &:nth-child(4) {
                top: -5px;
                right: 180px;
                left: initial;
                animation-duration: 3.5s;
                animation-delay: 0.6s;
            }
            &:nth-child(5) {
                top: -5px;
                right: 400px;
                left: initial;
                animation-duration: 3.5s;
                animation-delay: 0.8s;
            }
            &:nth-child(6) {
                top: -5px;
                right: 600px;
                left: initial;
                animation-duration: 6s;
                animation-delay: 3s;
            }
            &:nth-child(7) {
                top: 300px;
                right: -5px;
                left: initial;
                animation-duration: 3.75s;
                animation-delay: 1.2s;
            }
            &:nth-child(8) {
                top: -5px;
                right: 700px;
                left: initial;
                animation-duration: 3.25s;
                animation-delay: 1.4s;
            }
            &:nth-child(9) {
                top: 300px;
                right: -5px;
                left: initial;
                animation-duration: 1.5s;
                animation-delay: 2.25s;
            }
            &:nth-child(10) {
                top: -5px;
                right: 450px;
                left: initial;
                animation-duration: 2.25s;
                animation-delay: 2.75s;
            }
            
        }

    

        .wave {
            position: relative;
            display: block;
            transform: translateY(5px);
            z-index: 1;

        }
    }
    
    .services-section {
        padding: 50px 0px 100px 0px;
        text-align: center;
        background-color: #FFFFFF;
    
        h1 {
            width: 85%;
            margin: 0 auto;
            color: #121E52;
            padding:  0px 140px ;
            opacity: 0;
    
            span {
                color: #ED4622;
            }
        }
    
        .description {
            width: 50%;
            margin: 27px auto 0px auto;
            position: relative;
            z-index: 1;
        }
    
        .service-box-wrapper {
            position: relative;
    
            .service-background {
                width: 100%;
                position: absolute;
                left: 0;
                top: -100px;
                z-index: 0;
            }
    
            .services-box {
                display: flex;
                justify-content: center;
                gap: 30px;
                margin: 10px 140px 40px 140px;
                background-color: transparent;
                position: relative;
                z-index: 1;
                height: 580px;
        
                .service-card {
                    background-color: #FFFFFF;
                    box-shadow: 0px 2px 20px 4px rgb(0,0,0,0.2);
                    padding: 84px 30px 50px 30px;
                    border-radius: 10px;
                    width: 32%; 
                    position: absolute; 
                    left: 0px;
                    transition: all 0.4s cubic-bezier(.63,.15,.03,1.12);
                    height: 90%;    
                    .logo {
                        width: 190px;
                        height: 190px;
                        margin: 0 auto;
                    }
        
                    .card-title {
                        font-size: 32px;
                        font-weight: 500;
                        color: #1F45E4;
                        font-family: 'Poppins';
                        margin: 22px auto;
    
                    }
        
                    .card-info {
                        font-family: 'Lato';
                        font-size: 18px;
                        line-height: 163.2%;
                        margin: 0 auto;
    
                    }

                    &.card-1{ 
                        z-index: 10; transform: rotateZ(-2deg);
                    }
                    &.card-2{ 
                        z-index: 9; transform: rotateZ(-7deg);
                    }
                    &.card-3{ 
                        z-index: 8; transform: rotateZ(5deg);
                    }
                }
            }
    
            .learn-more-button {
                padding: 16px 50px;
            }
        }
    
    }

    .animate-services {
                    
        .bottom-h1 {
            margin-top: 5px;
            opacity: 0;
            animation: FadeInDownUp 500ms ease-in-out 0.6s 1 normal forwards;

        }

        .top-h1 {
            margin-bottom: 10px;
            opacity: 0;
            animation: FadeInDownUp 500ms ease-in-out 0.4s 1 normal forwards;
        }
    
        .description {
            opacity: 0;
            animation: RegularFadeIn 500ms ease-in-out 1s 1 normal forwards;
        }
    
        .service-box-wrapper {
    
            .service-background {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 1.3s 1 normal forwards;
            }
    
            .services-box {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 1.7s 1 normal forwards;

        
                .service-card {


                    &.card-1{
                        transform: rotateZ(0deg);
                    }

              
                    &.card-2{
                        animation: card106 500ms cubic-bezier(.63,.15,.03,1.12) 2.5s 1 normal forwards;
                    }
              
                    &.card-3{
                        animation: card212 500ms cubic-bezier(.63,.15,.03,1.12) 2.5s 1 normal forwards;

                    }
                }
            }
        }
    }

    .functionality-section {
        padding: 80px 0px;
        background-color: #FFFFFF;

    
        .functionality-box {
            opacity: 0;
            position: relative;
            width: 70%;
            margin: 0 auto;


            // .image {
            //     transform: rotateX(45deg) rotateZ(-25deg);
            //     transform-style: preserve-3d;
            //     transition: .3s;
            // }

            // .image {
            //     position: relative;
            //     z-index: 1;

            //     img {
            //         vertical-align: bottom;
            //         transition: .3s;
            //     }
            // }

            // .image::before,
            // .image::after {
            //     content: '';
            //     position: absolute;
            // }
            // .image::before {
            //     bottom: -8px;
            //     left: 0;
            //     background-color: #d0d0d0;
            //     width: 100%;
            //     height: 16px;
            //     transform: rotateX(90deg) translateY(-8px);
            // }
            // .image::after {
            //     top: 0;
            //     left: -8px;
            //     width: 16px;
            //     height: 100%;
            //     background-color: #bbb;
            //     transform: rotateY(90deg) translateX(8px);
            // }

            img {
                width: 100%;
            }
        }
    }

    .animate-functionality {
    
        .functionality-box {
            animation: RegularFadeIn 500ms ease-in-out 0.4s 1 normal forwards, Expand 500ms cubic-bezier(.63,.15,.03,1.12) 1.4s 1 normal forwards;
            // animation: RegularFadeIn 500ms ease-in-out 0.4s 1 normal forwards

            // .image {
            //     animation: Rotate3D 500ms cubic-bezier(.63,.15,.03,1.12) 1.5s 1 normal forwards;

            // }
        }
    }
    
    .why-us-section {
        padding: 30px 0px 0px 0px;
        background-color: #FFFFFF;
    
        > h1 {
            text-align: center;
            margin-bottom: 30px;
            margin-top: 30px;
        }
    
        .block {
            display: flex;
            padding: 65px 0px;
            align-items: center;
            
            h1 {
                color: #121E52;
                font-size: 42px;
            }

            &.animate-1, &.animate-2, &.animate-3, &.animate-4 {
                h1 {
                    opacity: 0;
                    animation: FadeInDownUp 500ms ease-in-out 0.3s 1 normal forwards; 
                }
                p{
                    opacity: 0;
                    animation: RegularFadeIn 500ms ease-in-out 0.5s 1 normal forwards;
                }

                .image-wrapper {
                    opacity: 0;
                    animation: SlideIn 1000ms ease-in-out 0.7s 1 normal forwards, RegularFadeIn 500ms ease-in-out 0.7s 1 normal forwards;
                }
            }
            
            
        }
    
        #block1 {
            padding-left: 140px;
    
            .content {
                width: 40%;
                span {
                    color: #4C6FFC;
                }
            }
    
            .image-wrapper {
                width: 60%;
                transform: translateX(150%);
    
                img {
                    width: 150%;
                }
            }
    
        }
    
        #block2 {
            padding-right: 140px;
            background-color: #F6F6F6;
    
            .content {
                width: 40%;
                margin-left: 100px;
                span {
                    color: #ED4622;
                }
            }
    
            .image-wrapper {
                width: 60%;
                margin-right: 100px;
                margin-left: -120px;
                transform: translateX(-150%);

    
                img {
                    width: 120%;
                }
            }
    
        }
    
        #block3 {
            padding: 0px;
            padding-left: 140px;
            margin: 0;

            .content {
                width: 40%;
                span {
                    color: #4C6FFC;
                }
            }
    
            .image-wrapper {
                width: 70%;
    
                .iso-wave {
                    width: 90%;
                    margin-left: 50px;
                    margin-top: 120px;
                    transform: translateX(0%);


                    svg {
                        height: 90%;
                    }

                    #CubeGroup1,
                    #CubeGroup2,
                    #CubeGroup3,
                    #CubeGroup4,
                    #CubeGroup5,
                    #CubeGroup6,
                    #CubeGroup7,
                    #CubeGroup8,
                    #CubeGroup9,
                    #CubeGroup10,
                    #CubeGroup11,
                    #CubeGroup12,
                    #CubeGroup13,
                    #CubeGroup14,
                    #CubeGroup15,
                    #CubeGroup16 {
                    animation: waveAnimation 1.4s ease-in-out alternate infinite;
                    animation-fill-mode: forwards;
                    }

                    polygon {
                    fill-opacity: 1;
                    }

                    #CubeGroup1 {
                        animation-delay: 0.2s;
                      }
                      
                      #CubeGroup2 {
                        animation-delay: 0.4s;
                      }
                      
                      #CubeGroup3 {
                        animation-delay: 0.6s;
                      }
                      
                      #CubeGroup4 {
                        animation-delay: 0.8s;
                      }
                      
                      #CubeGroup5 {
                        animation-delay: 1s;
                      }
                      
                      #CubeGroup6 {
                        animation-delay: 1.2s;
                      }
                      
                      #CubeGroup7 {
                        animation-delay: 1.4s;
                      }
                      
                      #CubeGroup8 {
                        animation-delay: 1.6s;
                      }
                      
                      #CubeGroup9 {
                        animation-delay: 1.8s;
                      }
                      
                      #CubeGroup10 {
                        animation-delay: 2s;
                      }
                      
                      #CubeGroup11 {
                        animation-delay: 2.2s;
                      }
                      
                      #CubeGroup12 {
                        animation-delay: 2.4s;
                      }
                      
                      #CubeGroup13 {
                        animation-delay: 2.6s;
                      }
                      
                      #CubeGroup14 {
                        animation-delay: 2.8s;
                      }
                      
                      #CubeGroup15 {
                        animation-delay: 3s;
                      }
                      
                      #CubeGroup16 {
                        animation-delay: 3.2s;
                      }

                }
                
            }
    
        }
    
        #block4 {
            padding-right: 140px;
            background-color: #F6F6F6;
            .content {
                width: 40%;
                margin-left: 100px;
                span {
                    color: #ED4622;
                }
                
            }
    
            .image-wrapper {
                width: 60%;
                margin-right: 140px;
                margin-left: -120px;
                transform: translateX(-150%);
    
                img {
                    width: 120%;
                }
            }
        }
    }
    
    .slogan-section {
        background: url('../Images/hero-page/slogan-background.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 120px 140px;
        margin: 0px 0px 0px 0px; 
    
        h1 {
            color: #FFFFFF;
        }
    
        h3 {
            font-family: 'Poppins';
            font-weight: 500;
            color:#FFFFFF;
            font-size: 24px;
            padding: 10px 0px 70px 0px;
        }
    
        .contact-box {
            .email-input {
                width: 300px;
            }

            button {
                outline: 1px solid;
                outline-color: rgba(255, 255, 255, .5);
                outline-offset: 0px;
                text-shadow: none;
                box-shadow: inset 0 0 20px rgba(255, 255, 255, 0), 0 2px 20px 4px #ffffff44;;
                transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

                &:hover {
                    box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2), 0 2px 20px 4px #ffffff44;
                    outline-color: rgba(255, 255, 255, 0);
                    outline-offset: 15px;
                    text-shadow: 1px 1px 2px #427388; 
                  }
            }
        }
    }
    .empty-space {
        width: 100%;
        height: 200px;
        background-color: #FFFFFF;
    }
}



@media only screen and (max-width: 1200px) {
    .hero-page {
        .hero-section {    
            background-position: -300px 0px;
            
            .hero-content {
                padding: 0px 60px;
                padding-top: 160px;
                width: 75%;
        
         
                h1 {
                    width: 75%;
                    margin: 20px 0px;
                }
        
                .description {
                    width: 75%;
                }
            }
        }
        
        .services-section {
            padding: 50px 0px 100px 0px;
        
            h1 {
                width: 80%;
                margin: 0 auto;
                padding:  0px 60px ;
                line-height: 120%;
            }
        
            .description {
                width: 80%;
                margin: 27px auto 0px auto;
            }
        
            .service-box-wrapper {
                position: relative;
        
                .service-background {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: -100px;
                    z-index: -1;
                }
        
                .services-box {
                    display: flex;
                    gap: 30px;
                    margin: 10px 60px 40px 60px;
                    height: 520px;
          
                    .service-card {
                        padding: 84px 30px 50px 30px;
        
                        .logo {
                            width: 150px;
                            height: 150px;
                            margin: 0 auto;
                        }
            
                        .card-title {
                            font-size: 30px;
                            margin: 22px auto;
        
                        }
            
                        .card-info {
                            font-size: 16px;
                            line-height: 163.2%;
                            margin: 0 auto;
        
                        }
                    }
                }
        
                .learn-more-button {
                    padding: 16px 50px;
                }
            }
        
        }

        .functionality-section {
            padding: 80px 0px;
    
        }
        .why-us-section {
            padding: 30px 0px 0px 0px;
        
            > h1 {
                text-align: center;
                margin-bottom: 30px;
                margin-top: 30px;
            }
        
            .block {
                display: flex;
                padding: 65px 0px;
                align-items: center;
                
                h1 {
                    font-size: 40px;
                }

                p {
                    font-size: 16px;
                }
                
            }
        
            #block1 {
                padding-left: 60px;
        
                .content {
                    width: 45%;

                }
        
                .image-wrapper {
                    width: 55%;
        
                    img {
                        width: 150%;
                    }
                }
        
            }
        
            #block2 {
                padding-right: 60px;
        
                .content {
                    width: 45%;
                    margin-left: 100px;

                }
        
                .image-wrapper {
                    width: 55%;
                    margin-right: 100px;
                    margin-left: -120px;
        
                    img {
                        width: 120%;
                    }
                }
        
            }
        
            #block3 {
                padding-left: 60px;
        
                .content {
                    width: 45%;

                }
        
                .image-wrapper {
                    width: 55%;
        
                    img {
                        width: 100%;
                        margin-left: 50px;
                    }
                }
            }
        
            #block4 {
                padding-right: 60px;
        
                .content {
                    width: 45%;
                    margin-left: 100px;  
                }
        
                .image-wrapper {
                    width: 55%;
                    margin-right: 140px;
                    margin-left: -120px;
        
                    img {
                        width: 120%;
                    }
                }
            }
        }
        
        .slogan-section {
            padding: 120px 60px;
        
            h3 {
                font-size: 20px;
            }
        
            .contact-box {
                .email-input {
                    width: 300px;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .hero-page {
        .hero-section {    
            background-position: -400px 0px;

            .hero-content {
                padding: 0px 50px;
                padding-top: 150px;
                width: 90%;
        
         
                h1 {
                    width: 80%;
                    margin: 20px 0px;
                }
        
                .description {
                    width: 60%;
                }
        
        
            }

        }
        
        .services-section {
            padding: 50px 0px 100px 0px;
        
            h1 {
                width: 90%;
                margin: 0 auto;
                padding:  0px 60px 0px
            }
        
            .description {
                width: 80%;
                margin: 0px auto 10px auto;
            }
        
            .service-box-wrapper {
                position: relative;
        
                .service-background {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: -70px;
                    z-index: -1;
                }
        
                .services-box {
                    display: flex;
                    gap: 20px;
                    margin: 10px 50px 40px 50px;
                    height: 420px;
            
                    .service-card {
                        padding: 64px 10px 40px 10px;
        
                        .logo {
                            width: 120px;
                            height: 120px;
                            margin: 0 auto;
                        }
            
                        .card-title {
                            font-size: 25px;
                            margin: 22px auto;
        
                        }
            
                        .card-info {
                            font-size: 14px;
                            line-height: 163.2%;
                            margin: 0 auto;
        
                        }
                    }
                }
        
                .learn-more-button {
                    padding: 16px 50px;
                }
            }
        
        }
        

        .why-us-section {
            padding: 30px 0px 0px 0px;
        
            > h1 {
                text-align: center;
                margin-bottom: 30px;
                margin-top: 30px;
            }
        
            .block {
                display: flex;
                padding: 50px 0px;
                align-items: center;
                
                h1 {
                    font-size: 30px;
                    line-height: 40px;
                }

                p {
                    font-size: 14px;
                }
                
            }
        
            #block1 {
                padding-left: 50px;
        
                .content {
                    width: 50%;
                }
        
                .image-wrapper {
                    width: 50%;
        
                    img {
                        width: 150%;
                    }
                }
        
            }
        
            #block2 {
                padding-right: 50px;
        
                .content {
                    width: 50%;
                    margin-left: 100px;
                }
        
                .image-wrapper {
                    width: 50%;
                    margin-right: 40px;
                    margin-left: -20px;
        
                    img {
                        width: 120%;
                    }
                }
        
            }
        
            #block3 {
                padding-left: 50px;
        
                .content {
                    width: 50%;

                }
        
                .image-wrapper {
                    width: 50%;
        
                    img {
                        width: 100%;
                        margin-left: 50px;
                    }
                }
        
            }
        
            #block4 {
                padding-right: 50px;
        
                .content {
                    width: 50%;
                    margin-left: 100px;                 
                }
        
                .image-wrapper {
                    width: 50%;
                    margin-right: 60px;
                    margin-left: -20px;
        
                    img {
                        width: 120%;
                    }
                }
            }
        }
        .slogan-section {

            padding: 120px 50px;
        

            h3 {
                font-size: 18px;
            }
        
            .contact-box {
                .email-input {
                    width: 300px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .hero-page {
        .hero-section {    
            background-position: -510px 0px;

            .hero-content {
                padding: 0px 30px;
                padding-top: 150px;
                width: 100%;
        
         
                h1 {
                    width: 75%;
                }
        
                .description {
                    width: 75%;
                }
        
            }
            .wave {
                transform: translateY(65px);
            }

        }
        
        .services-section {
        
            h1 {
                width: 100%;
                padding-bottom: 0px;
                line-height: 120%;
                
            }
        
            .service-box-wrapper {
                position: relative;
        
                .service-background {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: -70px;
                }
        
                .services-box {
                    flex-direction: column;
                    position: relative;
                    height:fit-content;
                    gap: 30px;
                    padding: 10px 30px 40px 30px;
          
            
            
                    .service-card {
                        width: 100%;
                        position: relative;
                        padding: 64px 40px 40px 40px;
                    }
                }
        
            }
        
        }

        .animate-services {
                    
            .bottom-h1 {
                animation: FadeInDownUp 500ms ease-in-out 1.2s 1 normal forwards;
            }
    
            .top-h1 {
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.5s 1 normal forwards;
            }
        
            .description {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 1.5s 1 normal forwards;
            }
        
            .service-box-wrapper {
        
                .service-background {
                    opacity: 0;
                    animation: RegularFadeIn 500ms ease-in-out 1.7s 1 normal forwards;
                }
        
                .services-box {
                    opacity: 0;
                    animation: RegularFadeIn 500ms ease-in-out 2.1s 1 normal forwards;
    
            
                    .service-card {
    
    
                        &.card-1{
                            transform: rotateZ(0deg);
                        }
    
                  
                        &.card-2{
                            animation: none;
                            transform: rotateZ(0deg);

                        }
                  
                        &.card-3{
                            animation: none;
                            transform: rotateZ(0deg);
                        }
                    }
                }
            }
        }
        

        .why-us-section {

            >h1 {
                padding: 0px 0px 20px 0px;
            }
        
            .block {
                display: flex;
                flex-direction: column;
                padding: 70px 0px;
                align-items: center;
                text-align: center;
                
                h1 {
                    font-size: 26px;
                    line-height: 36px;
                }

                p {
                    font-size: 14px;
                    padding: 20px 0px 60px 0px;

                }
                .image-wrapper {
                    display: none;
                }
                
            }
        
            #block1 {
                padding-left: 0px;
        
                .content {
                    width: 75%;

                }
        
            }
        
            #block2 {
                flex-direction: column-reverse;
                padding-left: 0px;
                padding-right: 0px;

                .content {
                    width: 75%;
                    margin-left: 0px;

                    p {
                        padding-bottom: 10px;
                    }

                }
        

        
            }
        
            #block3 {
                padding-left: 0px;
                padding: 70px 0px;
        
                .content {
                    width: 75%;

                    p {
                        padding-bottom: 10px;
                    }
                }
        

    
            }
        
            #block4 {
                flex-direction: column-reverse;
                padding-right: 0px;

                .content {
                    width: 75%;
                    margin-left: 0px;

                    p {
                        padding-bottom: 10px;
                    }

                }
        

            }
        }
        .slogan-section {

            padding: 120px 30px;
        

            h3 {
                font-size: 18px;
            }
        
            .contact-box {
                .email-input {
                    width: 250px;
                }
            }
        }

    }
}

@media only screen and (max-width: 576px) {
    .hero-page {
        .hero-section {    
            background-position: -610px 0px;

            .hero-content {        
                h1 {
                    width: 90%;
                }
        
                .description {
                    width: 90%;
                }

                .contact-box {
                    width: 100%;
                }
        
            }
            .wave {
                transform: translateY(65px);
            }

        }
        
        .services-section {
        
            h1 {
                width: 100%;
                padding-bottom: 0px;
            }
        
            .service-box-wrapper {
                position: relative;
                padding: 0px 0px;
                width: 100%;
        
                .service-background {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: -70px;
                }
        
                .services-box {
                    flex-direction: column;
                    position: relative;
                    height:fit-content;
                    align-items: center;
                    justify-content: center;    
                    margin: 0px 20px;
                    padding: 10px;
                    width: 90%;
                    margin-bottom: 30px;
          
            
            
                    .service-card {
                        width: 100%;
                        position: relative;
                        padding: 64px 20px 40px 20px;
                    }
                }
        
            }
        
        }
        
        .slogan-section {
            background-position: -310px 0px;
            padding: 120px 30px;
        

            h3 {
                font-size: 18px;
            }
        
            .contact-box {
                .email-input {
                    width: 250px;
                }
            }
        }

    }
}


@media only screen and (max-width: 436px) {
    .hero-page {
        .hero-section {    
            .wave {
                transform: translateY(40px);
            }

        }
    
    }
    
}


@media only screen and (max-width: 428px) {
    .hero-page {
        .hero-section {    
            .wave {
                transform: translateY(10px);
            }

        }
    
    }
}