@import "../App.scss";
@import '../Animations.scss';

@keyframes scale {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.contact-us-page {

    .title-section {
        width: 100%;
        padding: 0px 140px;
        padding-top: 200px;
        background-color: #FFFFFF;

        h4 {
            margin: 30px 0px;
            font-size: 16px;
            color: #383838;

            opacity: 0;
            animation: FadeInDownUp 500ms ease-in-out 0.5s 1 normal forwards;

        }

        h1 {
            width: 70%;
        }

        .top-h1 {
            opacity: 0;
            animation: FadeInDownUp 500ms ease-in-out 0.8s 1 normal forwards;
        }

        .bottom-h1 {
            opacity: 0;
            animation: FadeInDownUp 500ms ease-in-out 1.1s 1 normal forwards;
        }
    }

    .map-section {
        width: 100%;
        min-height: 700px;
        padding: 0px;
        background-image: url('../Images/contact-us-page/map-background.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        
        .map-container {
            padding: 140px 140px;
            display: flex;
            justify-content: space-between;
            gap: 20px;
            opacity: 0;
            animation: FadeInDownUp 500ms ease-in-out 1.5s 1 normal forwards;


            .information {
                padding: 100px 0px;
                p {
                    padding: 10px 0pc 20px 0pc;
                    white-space: normal;
                }
            }

            .map {
                width: 60%;
                background-color: antiquewhite;

                iframe {
                    width: 100%;
                    height: 100%;
                    border: none;
                    box-shadow: 0px 0px 20px 0px rgb(0,0,0,0.4);
                }
            }
        }
    }

    .form-section {
        background-color: #FFFFFF;
        padding: 0px 0px 100px 0px;
        
        .form-section-container {
            padding: 50px 140px;
            display: flex;
            justify-content: space-between;

            .form-information {
                width: 45%;

                .social-box {
                    a {
                        color: #383838;
                        text-decoration: none;
                    }
                    
                    i {
                        display: block;
                        margin: 25px 0;
                        font-size: 20px;
                    }

                    .dash {
                        height: 4px;
                        width: 20px;
                        background-color:rgb(0,0,0);
                        transform: rotate(90deg);
                        margin: 25px 0px;
                    }

                    .follow-us {
                        font-size: 20px;
                        font-family: 'Poppins';
                        font-weight: 500;
                        transform: rotate(-90deg) translate(-45px, -40px);
                        width: 100px;
                        margin: 0;
                        padding: 0;

                    }
                }
            }
            
            .form {
                width: 50%;
                
                .form-entry {
                    margin: 0pc 0pc 40px 0px;
                    label {
                        display: block;
                        margin-bottom: 15px;
                        font-size: 18px;
                        font-family: 'Poppins';
                        font-weight: 500;
                    }

                    input {
                        font-size: 16px;
                        font-family: 'Lato';
                        font-weight: 400;
                        border: 2px solid #BBC8D4;
                        padding: 16px 16px;
                        background-color: transparent;
                        width: 100%;
                        border-radius: 4px;
                        outline: none;
            
                        &::placeholder {
                            color: #BBC8D4;
                        }
            
                    }

                    textarea {
                        font-size: 16px;
                        font-family: 'Lato';
                        font-weight: 400;
                        border: 2px solid #BBC8D4;
                        padding: 16px 16px;
                        background-color: transparent;
                        width: 100%;
                        border-radius: 4px;
                        outline: none;
                        color: #383838;
                        &::placeholder {
                            color: #BBC8D4;                        
                        }     
                    
                    }
                }
                
                button {
                    margin: 20px 0px;
                    box-shadow: none;
                    width: 200px;
                }
        
            }
        }

        &.animate-form {
            .form-section-container {
                .form-information {
                    opacity: 0;
                    animation: FadeInUpDown 500ms ease-in-out 0.4s 1 normal forwards;

                    .social-box {
                        
                        i {
                            opacity: 0;
                        }

                        #icon-1 {
                            transform: translateX(70px);
                            animation: SlideIn 500ms ease-in-out 0.8s 1 normal forwards,  RegularFadeIn  400ms ease-in-out 0.6s 1 normal forwards;  
                        }
                        #icon-2 {
                            transform: translateX(60px);
                            animation: SlideIn  500ms ease-in-out 1s 1 normal forwards, RegularFadeIn  400ms ease-in-out 0.8s 1 normal forwards;   
                        }
                        #icon-3 {
                            transform: translateX(50px);
                            animation: SlideIn  500ms ease-in-out 1.2s 1 normal forwards, RegularFadeIn  400ms ease-in-out 1s 1 normal forwards; ;
                        }
                        #icon-4 {
                            transform: translateX(40px);
                            animation: SlideIn  500ms ease-in-out 1.4s 1 normal forwards, RegularFadeIn  400ms ease-in-out 1.2s 1 normal forwards; ;
                        }
                        #icon-5 {
                            transform: translateX(30px);
                            animation: SlideIn  500ms ease-in-out 1.6s 1 normal forwards,RegularFadeIn  400ms ease-in-out 1.4s 1 normal forwards; ;
                        }

    
                        .dash {
                            opacity: 0;
                            animation: RegularFadeIn  500ms ease-in-out 1.5s 1 normal forwards
                        }
    
                        .follow-us {
                            opacity: 0;
                            animation: RegularFadeIn  500ms ease-in-out 1.5s 1 normal forwards

                        }
                    }
                }
                
                .form {
                    opacity: 0;
                    animation: RegularFadeIn  500ms ease-in-out 1.7s 1 normal forwards;

                    .form-entry {

                    }
                    
                    button {
                        margin: 20px 0px;
                        box-shadow: none;
                        width: 200px;
                    }
            
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .contact-us-page {
        .title-section {
            padding: 0px 60px;
            padding-top: 200px;

        }

        .map-section {
            padding: 0px;
            background-position: -100px 0px;
            
            .map-container {
                padding: 140px 60px;
    
                .information {
                    padding: 80px 0px;

                    h2 {
                        font-size: 28px;
                    }
                }
            }
        }

        .form-section {
            
            .form-section-container {
                padding: 50px 60px;
            }
        }
    
    }
}
@media only screen and (max-width: 992px) {
    .contact-us-page {
        .title-section {
            padding: 0px 50px;
            padding-top: 150px;

        }

        .map-section {
            padding: 0px;
            background-position: -100px 0px;
            
            .map-container {
                padding: 140px 50px;
    
                .information {
                    padding: 40px 0px;

                    
                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }

        .form-section {
            
            .form-section-container {
                padding: 50px 50px;
            }
        }
    
    }
}
@media only screen and (max-width: 768px) {
    .contact-us-page {
        .title-section {
            padding: 0px 30px;
            padding-top: 150px;
            
            h1 {
                width: 100%;
            }

        }

        .map-section {
            padding: 0px;
            background-position: -100px 0px;
            min-height: 400px;

            .map-container {
                padding: 50px 30px;
                display: block;
    
                .information {
                    padding: 40px 0px;
                    margin: 0 auto;

                    
                    h2 {
                        font-size: 24px;
                    }

                }

                .map {
                    height: 300px;
                    width: 100%;
                }
            }
        }

        .form-section {
            
            .form-section-container {
                padding: 50px 30px;
                display: block;

                .form-information {
                    width: 100%;

                    p {
                        padding: 20px 0px;
                    }
    
                    .social-box {
                        margin: 0px 0px 30px 0px;
                        display: flex;
                        i {
                            margin: 0px 20px 0pc 0px;
                            font-size: 20px;
                        }
    
                        .dash {
                            transform: rotate(0deg);
                            margin: 10px 0px;
                        }
    
                        .follow-us {
                            font-size: 20px;
                            font-family: 'Poppins';
                            font-weight: 500;
                            transform: rotate(0deg) translate(0px, -2px);
                            margin: 0px 20px;

                        }
                    }
                }

                .form {
                    width: 100%;
                }
            }
        }
    
    }
}
@media only screen and (max-width: 576px) {}