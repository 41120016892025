@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body {
    color: black;
    background-color: var(--bg-secondary);
    margin: 0;
    padding: 0;
    position: absolute;
    min-height: 100vh;
    width: 100%;
    overflow :hidden;
    overflow-y: auto;
    background-color: black;

    .span-o {
        color: #ED4622;
    }

    .span-b {
        color: #4C6FFC;
    }

    h1 {
        font-family: 'Poppins';
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
    }

    h2 {
        font-family: 'Poppins';
        font-size: 32px;
        font-weight: 500;
        line-height: 60px;
    }

    p {
        font-size: 18px;
        font-family: 'Lato';
        line-height: 190.2%;
        padding: 28px 0px 60px 0px;
    }

    h4 {
        font-size: 18px;
        font-family: 'Poppins';
        font-weight: 500;
        white-space: nowrap;    
    }
    
    button {
        color: #FFFFFF;
        background-color: #1F45E4;
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 16px;
        padding: 16px 35px;
        border-radius: 2px;
        border: none;
        box-shadow: 0px 2px 20px 2px rgb(0,0,0,0.2);
        position: relative;
        cursor: pointer;
    }

    .secondary-button {
        color: #1F45E4;
        background-color: transparent;
        border: 2px solid #1F45E4;
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 16px;
        padding: 14px 35px;
        margin: 0px 30px;
        border-radius: 2px;
        box-shadow: 0px 2px 20px 2px rgb(0,0,0,0.2);
        cursor: pointer;
    }

    .contact-box {
        .email-input {
            font-size: 16px;
            font-family: 'Lato';
            font-weight: 400;
            border: 2px solid #FFFFFF;
            padding: 16px 16px;
            background-color: transparent;
            width: 50%;
            border-radius: 4px;
            outline: none;
            color: #FFFFFF;

            &::placeholder {
                color: #FFFFFF;
            }

        }

        .contact-us-button {
            box-shadow: 0 2px 20px 4px #ffffff44;
        }
    }

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 12px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 3px solid transparent;
        background-color: rgba(0, 0, 0, 0.164);
        background-clip: content-box;
        
    }
}

@media only screen and (max-width: 1200px) {

    body {    
        h1 {
            font-size: 42px;
            line-height: 60px;
        }
    
        p {
            font-size: 18px;
            font-family: 'Lato';
            line-height: 190.2%;
        }

        h4 {
            font-size: 16px;
        }
        
        button {

            font-size: 14px;
            padding: 12px 25px;
        }

        

        .secondary-button {
            font-size: 14px;
            padding: 10px 25px;
            margin: 0px 30px;
        }
    
        .contact-box {
            .email-input {
                padding: 12px 14px;    
            }
    
        }
    }
}

@media only screen and (max-width: 992px) {

    body {    
        h1 {
            font-size: 38px;
            line-height: 60px;
        }
    
        p {
            font-size: 16px;
            font-family: 'Lato';

        }
        
        button {

            font-size: 14px;
            padding: 12px 25px;
        }

        .secondary-button {
            font-size: 14px;
            padding: 10px 25px;
            margin: 0px 30px;
        }
    
        .contact-box {
            .email-input {
                padding: 12px 14px;    
            }
    
        }
    }
}

@media only screen and (max-width: 768px) {

    body {    
        h1 {
            font-size: 34px;
            line-height: 47px;
        }
    
        p {
            font-size: 14px;
            font-family: 'Lato';

        }
        
        button {
            font-size: 14px;
            padding: 12px 25px;
        }
    
        .contact-box {
            .email-input {
                padding: 12px 14px;    
            }
    
        }
    }
}

@media only screen and (max-width: 576px) {
    body {
        .contact-box {
            .email-input {
                padding: 12px 14px;    
    
            }
    
            .contact-us-button {
                box-shadow: 0px 2px 20px 4px #ffffff44;
                display: block;
                margin: 30px 0px 0px 0px;
            }
        }

        .secondary-button {
            font-size: 14px;
            padding: 10px 25px;
            margin: 20px 0px;
        }
    
    }
} 