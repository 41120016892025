@import '../App.scss';
@import '../Animations.scss';

@keyframes addColor {
    0% {
        -webkit-mask-position: 0% 0%;
        mask-position: 0% 0%;

    }
    100% {
        -webkit-mask-position: 100% 0%;
        mask-position: 100% 0%;
    }
    
}


@keyframes slide-transparency {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.animate-opacity {
    animation-name: slide-transparency;
    animation-iteration-count: infinite;
}

.freq-5 {
    animation-duration: 5s;
}

.freq-6 {
    animation-duration: 6.33s;
}

.freq-7 {
    animation-duration: 7s;
}

.freq-8 {
    animation-duration: 8.88s;
}

.freq-9 {
    animation-duration: 9s;
}

.freq-10 {
    animation-duration: 10.17s;
}

.delay-2 {
    animation-delay: 1.33s;
}

.delay-4 {
    animation-delay: 2.11s;
}

.bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient( 180deg, rgba(27,24,31,1) 21.2%, rgb(26, 22, 61) 84.8% );
}

.z-1,
.z-2,
.z-3 {
    position: absolute;
    width: 150vw;
    height: 150vw;
    left: -50vw;
    top: -50vw;
    transform-origin: center;
}

.tile {
    overflow: hidden;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    background-size: 512px 512px;
}

.top-left {
    background-image: url("../Images/indurstries-page/stars/topleft.png");
}

.top-right {
    background-image: url("../Images/indurstries-page/stars/topright.png");
}

.bottom-left {
    background-image: url("../Images/indurstries-page/stars/bottomleft.png");
}

.bottom-right {
    background-image: url("../Images/indurstries-page/stars/bottomright.png");
}
.industries-page {
    min-height: 700px;

    .carousel-section {
        padding: 150px 140px 80px 140px;
        text-align: center;
        color: #FFFFFF;
        h1 {
            width: 85%;
            margin: 30px auto;
        }
        .description {
            width: 50%;
            margin: 0 auto;
        }
        
        &.animate {
            h1 {
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.5s 1 normal forwards;
            }

            p {
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 1s 1 normal forwards;
            }

            .carousel {
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 1.5s 1 normal forwards;

            }

                    

        }
        
    }

    .carousel {
        width: 100%;
        max-width: 800px;
        max-height: 600px;
        height: 180px;
        transform-style: preserve-3d;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 0px auto;
        

        input[type=radio] {
            display: none;
        }

        h3 {
            font-family: "Poppins";
            margin: 20px;
            font-weight: 400;
        }
                
        .cards {
            position: relative;
            width: 100%;
            height: 100%;
            margin-bottom: 20px;

            .card {
                position: absolute;
                width: 60%;
                height: 100%;
                left: 0;
                right: 0;
                margin: auto;
                transition: transform .4s ease;
                cursor: pointer;

                .card-title {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    text-align: center;
                    background-color: #1F45E4;
                    color: #FFFFFF;
                    font-size: 32px;
                    font-family: Poppins;
                    font-weight: 500;
                    padding: 20px;
                }
            }
        }
          
    }

    .carousel-blocks {
        padding: 0px 140px;
        padding-bottom: 100px;
        color: #FFFFFF;

        opacity: 0;
        animation: FadeInDownUp 500ms ease-in-out 1.5s 1 normal forwards;
        
        .block {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;

            padding: 100px 0px;
            .content-b {
                width: 45%;

                h2 {
                    font-size: 42px;
                    font-weight: 600;
                }

                span {
                    color:#1F45E4
                }
            }

            .content-o {
                width: 45%;

                h2 {
                    font-size: 42px;
                    font-weight: 600;
                }
                span {
                    color: #ED4622
                }
            }

            
            .mask-container {
                width: 55%;
                position: relative;

                img {
                    width: 100%;
                    display: block;

                }

                .color-image {
                    position: absolute;
                    background-image: url('../Images/indurstries-page/coating-thickness-colored.png');
                    inset: 0;
                    background-size: cover;

                    // -webkit-mask-image: url('../Images/indurstries-page/sprite-mask.png');
                    // -webkit-mask-size: cover;
                    // -webkit-mask-position: 0% 0%;

                    // mask-image: url('../Images/indurstries-page/sprite-mask.png');
                    // mask-size: cover;
                    // mask-position: 0% 0%;


                }
            }

            .mask-container-2 {
                width: 55%;
                position: relative;

                img {
                    width: 100%;
                    display: block;
                }

                .color-image {
                    position: absolute;
                    background-image: url('../Images/indurstries-page/saturared-car-battery.png');
                    inset: 0;
                    background-size: cover;

                    // -webkit-mask-image: url('../Images/indurstries-page/sprite-mask.png');
                    // -webkit-mask-size: cover;
                    // -webkit-mask-position: 0% 0%;

                    // mask-image: url('../Images/indurstries-page/sprite-mask.png');
                    // mask-size: cover;
                    // mask-position: 0% 0%;
                }
            }
            
            
            .mask-container-3 {
                width: 50%;
                position: relative;
                opacity: 0;

                img {
                    margin: 0 auto;
                    width: 90%;
                    display: block;
                    -webkit-mask-image: url('../Images/indurstries-page/fade-mask.png');
                    -webkit-mask-size: cover;
                    -webkit-mask-position: 0% 0%;
                }

            }

            .mask-container-4 {
                
                width: 50%;
                position: relative;

                img {
                    width: 100%;
                    display: block;
                }

                .color-image {
                    position: absolute;
                    background-image: url('../Images/indurstries-page/saturated-vegetables.png');
                    inset: 0;
                    background-size: cover;

                    // -webkit-mask-image: url('../Images/indurstries-page/sprite-mask.png');
                    // -webkit-mask-size: cover;
                    // -webkit-mask-position: 0% 0%;

                }
            }

            .mask-container-5 {
                width: 50%;
                position: relative;
                opacity: 0;
                margin: 20px 0px;

                img {
                    margin: 0 auto;
                    width: 100%;
                    display: block;
                    -webkit-mask-image: url('../Images/indurstries-page/peanut-mask.png');
                    -webkit-mask-size: contain;
                    mask-repeat: no-repeat;
                    -webkit-mask-position: 0% 40%;
                }

            }

            .mask-container-7 {
                width: 50%;
                position: relative;
                opacity: 0;
                margin: 20px 0px;

                img {
                    margin: 0 auto;
                    border-radius: 5px;
                    width: 100%;
                    display: block;
                    -webkit-mask-image: url('../Images/indurstries-page/rectangle-mask.png');
                    -webkit-mask-size: cover;
                    mask-repeat: no-repeat;
                }

            }

            .mask-container-6 {
                width: 50%;
                position: relative;

                img {
                    width: 100%;
                    display: block;
                }

                .color-image {
                    position: absolute;
                    background-image: url('../Images/indurstries-page/saturated-airport.png');
                    inset: 0;
                    background-size: cover;

                    // -webkit-mask-image: url('../Images/indurstries-page/sprite-reverse-mask.png');
                    // -webkit-mask-size: cover;
                    // -webkit-mask-position: 0% 0%;
                }
            }

            &.animate {
                .content-b {
                    h2 {
                        opacity: 0;
                        animation: FadeInDownUp 500ms ease-in-out 0.5s 1 normal forwards;
                    }
        
                    p {
                        opacity: 0;
                        animation: FadeInDownUp 500ms ease-in-out 1s 1 normal forwards;
                    }
                }
    
                .content-o {
                    h2 {
                        opacity: 0;
                        animation: FadeInDownUp 500ms ease-in-out 0.5s 1 normal forwards;
                    }
        
                    p {
                        opacity: 0;
                        animation: FadeInDownUp 500ms ease-in-out 1s 1 normal forwards;
                    }
                }

                .mask-animate {
                    opacity: 0;
                    animation: RegularFadeIn 500ms ease-in-out .3s 1 normal forwards;

                    .color-image {

                        animation: addColor 4s steps(69) forwards ;
                        animation-delay: 1s;
                    }
                }
            }
        }
        .reverse-block {
            flex-direction: row-reverse;
        }

        &.inactive {
            display: none;
        }
    }
}

#item-1:checked ~ .cards #song-3, #item-2:checked ~ .cards #song-1, #item-3:checked ~ .cards #song-2 {
  transform: translatex(-60%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1:checked ~ .cards #song-2, #item-2:checked ~ .cards #song-3, #item-3:checked ~ .cards #song-1 {
  transform: translatex(60%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1:checked ~ .cards #song-1, #item-2:checked ~ .cards #song-2, #item-3:checked ~ .cards #song-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;
  
  img {
    box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
  }
}


@media only screen and (max-width: 1200px) {
    .industries-page {
    
        .carousel-section {
            padding: 150px 60px 80px 60px;

            h1 {
                width: 85%;
            }
            .description {
                width: 70%;
            }
    
        }
    
        .carousel {
            width: 100%;
            max-width: 700px;
            max-height: 600px;
            height: 180px;

            margin: 0px auto;
            
    
                    
            .cards {

    
                .card {
                    width: 60%;
    
                    .card-title {
                    }
                }
            }
              
        }
    
        .carousel-blocks {
            padding: 0px 60px;
            padding-bottom: 100px;

    
            
            .block {
    
                padding: 60px 0px;
                .content-b {
                    width: 45%;
    
                    h2 {
                        font-size: 36px;
                    }
    
                }
    
                .content-o {
                    width: 45%;
    
                    h2 {
                        font-size: 36px;
                    }

                }
    
                
                .mask-container {
                    width: 55%;

                }
    
                .mask-container-2 {
                    width: 55%;
               
                }
                
                
                .mask-container-3 {
                    width: 50%;    
                }
    
                .mask-container-4 {
                    width: 50%;
                }
    
                .mask-container-5 {
                    width: 50%;
                    margin: 20px 0px;    
                }
    
                .mask-container-7 {
                    width: 50%;    
                }
    
                .mask-container-6 {
                    width: 50%;
    
    
                }
            }
        }
    }
}


@media only screen and (max-width: 992px) {
    .industries-page {
    
        .carousel-section {
            padding: 150px 50px 80px 50px;

            h1 {
                width: 90%;
            }
            .description {
                width: 85%;
            }
    
        }
    
        .carousel {
            width: 100%;
            max-width: 500px;
            max-height: 600px;
            height: 180px;

            margin: 0px auto;
            
    
                    
            .cards {

    
                .card {
                    width: 70%;
    
                    .card-title {
                    }
                }
            }
              
        }
    
        .carousel-blocks {
            padding: 0px 50px;
            padding-bottom: 50px;

    
            
            .block {
    
                padding: 50px 0px;
                .content-b {
                    width: 45%;
    
                    h2 {
                        font-size: 30px;
                        line-height: 150%;
                    }
    
                }
    
                .content-o {
                    width: 45%;
    
                    h2 {
                        font-size: 30px;
                        line-height: 150%;
                    }

                }
    
                
                .mask-container {
                    width: 55%;

                }
    
                .mask-container-2 {
                    width: 55%;
               
                }
                
                
                .mask-container-3 {
                    width: 50%;    
                }
    
                .mask-container-4 {
                    width: 50%;
                }
    
                .mask-container-5 {
                    width: 50%;
                    margin: 20px 0px;    
                }
    
                .mask-container-7 {
                    width: 50%;    
                }
    
                .mask-container-6 {
                    width: 50%;
    
    
                }
            }
        }
    }
}



@media only screen and (max-width: 768px) {
    .industries-page {
    
        .carousel-section {
            padding: 150px 30px 80px 30px;

            h1 {
                width: 95%;
            }
            .description {
                width: 90%;
            }
    
        }
    
        .carousel {
            width: 100%;
            max-width: 400px;
            height: 165px;
            margin: 0px auto;
            
    
                    
            .cards {
    
                .card {
                    width: 70%;
    
                    .card-title {
                        font-size: 25px;
                    }
                }
            }
              
        }
    
        .carousel-blocks {
            padding: 0px 30px;
            padding-bottom: 50px;

    
            
            .block {
                position: relative;
                padding: 50px 0px;

                .content-b {
                    width: 75%;
                    position: absolute;
                    z-index: 2;
                    background-color: #000000a5;
                    padding: 30px 20px 0px 20px;
                    top: -10px;
    
                    h2 {
                        font-size: 30px;
                        line-height: 150%;
                    }
    
                }
    
                .content-o {
                    width: 75%;
                    position: absolute;
                    z-index: 2;
                    background-color: #000000c1;
                    padding: 30px 20px 0px 20px;
                    top: 0;
    
                    h2 {
                        font-size: 30px;
                        line-height: 150%;
                    }

                }
    
                
                .mask-container {
                    width: 100%;

                }
    
                .mask-container-2 {
                    width: 100%;
                    margin: 100px 0px;
                }
                
                
                .mask-container-3 {
                    width: 100%;    
                }
    
                .mask-container-4 {
                    width: 100%;    
                }
    
                .mask-container-5 {
                    width: 100%;    
                    margin: 20px 0px;    
                }
    
                .mask-container-7 {
                    width: 100%;    
                }
    
                .mask-container-6 {
                    width: 100%;    
    
    
                }
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .industries-page {
    
        .carousel-section {
            padding: 150px 30px 80px 30px;

            h1 {
                width: 95%;
            }
            .description {
                width: 95%;
            }
    
        }
    
        .carousel {
            width: 100%;
            max-width: 400px;
            height: 300px;
            margin: 0px auto;
            
    
                    
            .cards {

                margin-top: 100px;
                .card {
                    width: 70%;
                    height: 80px;
    
                    .card-title {
                        font-size: 25px;
                    }
                }
            }
              
        }
    
        .carousel-blocks {
            padding: 0px 30px;
            padding-bottom: 50px;

    
            
            .block {
                position: relative;
                padding: 50px 0px;

                .content-b {
                    width: 90%;
    
                    h2 {
                        font-size: 25px;
                        line-height: 150%;
                    }
    
                }
    
                .content-o {
                    width: 90%;

    
                    h2 {
                        font-size: 25px;
                        line-height: 150%;
                    }

                }
    
            
            }
        }
    }

    #item-1:checked ~ .cards #song-3, #item-2:checked ~ .cards #song-1, #item-3:checked ~ .cards #song-2 {
        transform: scale(.8) translateY(-100%);
        opacity: .4;
        z-index: 0;
      }
      
      #item-1:checked ~ .cards #song-2, #item-2:checked ~ .cards #song-3, #item-3:checked ~ .cards #song-1 {
        transform: scale(.8) translateY(100%);
        opacity: .4;
        z-index: 0;
      }
      
      #item-1:checked ~ .cards #song-1, #item-2:checked ~ .cards #song-2, #item-3:checked ~ .cards #song-3 {
        transform: translatex(0) scale(1);
        opacity: 1;
        z-index: 1;
        
        img {
          box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
        }
      }
      
}


@media only screen and (max-width: 576px) {
    .industries-page {
    
        .carousel-section {
            padding: 150px 30px 80px 30px;

            h1 {
                width: 95%;
            }
            .description {
                width: 95%;
            }
    
        }
    
        .carousel {
            width: 100%;
            height: 300px;
            margin: 0px auto;
            
    
                    
            .cards {

                margin-top: 100px;
                .card {
                    width: 90%;
                    height: 80px;
    
                    .card-title {
                        font-size: 25px;
                    }
                }
            }
              
        }
    
        .carousel-blocks {
            padding: 0px 30px;
            padding-bottom: 50px;

    
            
            .block {
                position: relative;
                padding: 50px 0px;
                display: block;

                .content-b {
                    width: 100%;
                    position: relative; 
                    background-color: transparent;
                    text-align: center;
    
                    h2 {
                        font-size: 20px;
                        line-height: 150%;
                    }
    
                }
    
                .content-o {
                    width: 100%;
                    position: relative;
                    background-color: transparent;
                    text-align: center;

    
                    h2 {
                        font-size: 20px;
                        line-height: 150%;
                    }

                }

                .mask-container {
                    width: 100%;

                }
    
                .mask-container-2 {
                    width: 100%;
                    margin: 0px 0px 0px 0px;
                }
                
                
                .mask-container-3 {
                    width: 100%; 
   
                }
    
                .mask-container-4 {
                    width: 100%;    
                }
    
                .mask-container-5 {
                    width: 100%;    
                    margin: 20px 0px;    
                }
    
                .mask-container-7 {
                    width: 100%;    
                }
    
                .mask-container-6 {
                    width: 100%;    
    
    
                }
    
            
            }
        }
    }
      
}