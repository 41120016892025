@import '../App.scss';

.footer {
    background-color: #121E52;

    .footer-wave {
        transform: rotate(180deg) translateY(5px) ;
        
    }

    .footer-content {
        background-color: #121E52;
        display: flex;
        margin: 0px 140px 130px 140px;
        gap: 60px;
        justify-content: space-between;
        color: #FFFFFF;
        transform: translateY(-50px);
        

        .column {

            .company-logo {
                width: 150px;
            }

            .subtitle {
                font-size: 16px;
                font-family: 'Lato';
                margin: 20px 0px;
                width: 70%;
            }

            ul{
                list-style: none;
                li {
                    margin: 30px 0px;
                    font-size: 16px;
                    font-family: 'Lato';
                    cursor: pointer;
                }
            }
        }
        
    }

    .copyright {
        font-family: 'Lato';
        font-size: 14px;
        color: #FFFFFF;
        margin: 0px 140px;
        padding: 30px 0px;
    }
}


@media only screen and (max-width: 1200px) {
    .footer {
    
    
        .footer-content {
            display: flex;
            margin: 0px 60px 130px 60px;
            justify-content:start;
            transform: translateY(-50px);
            gap: 30px;
            
            
            .column {

    
                .company-logo {
                    width: 130px;
                }
            }
            
        }
    
        .copyright {
            margin: 0px 60px;
        }
    }
}
@media only screen and (max-width: 992px) {
    .footer {
    
    
        .footer-content {
            display: flex;
            flex-wrap: wrap;
            margin: 0px 60px 130px 60px;
            justify-content:start;
            transform: translateY(-50px);
            gap: 40px;
            
            
            .column {
                width: 45%;
    
                .company-logo {
                    width: 130px;
                }
            }
            
        }
    
        .copyright {
            margin: 0px 60px;
        }
    }
}
@media only screen and (max-width: 768px) {
    .footer {
    
    
        .footer-content {
            display: flex;
            flex-wrap: wrap;
            margin: 50px 50px 130px 50px;
            justify-content:start;
            transform: translateY(-50px);
            gap: 40px;
            
            
            .column {
                width: 45%;
    
                .company-logo {
                    width: 130px;
                }
            }
            
        }
    
        .copyright {
            margin: 0px 60px;
        }
    }
}