@import '../App.scss';

.blog-page {
    width: 100%;
    min-height: 700px;
    padding: 0px;
    background-color: #FFFFFF;

    h1 {
        padding-top: 260px;
        width: 50%;
        text-align: center;
        margin: 0 auto;
        opacity: 0;
        animation: FadeInDownUp 500ms ease-in-out 0.5s 1 normal forwards;
    }

    .writer {
        display: flex;
        margin: 60px auto;
        width: 50%;
        align-items: center;
        opacity: 0;
        animation: FadeInDownUp 500ms ease-in-out 1s 1 normal forwards;

        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }


        .divider {
            background-color: #383838;
            width: 2px;
            height: 10px;

        }

        p {
            font-size: 16px;
            font-family: Lato;
            margin: 0px 20px 0px 40px;
            padding: 0px;
        }
    }

    .main-image {
        margin: 0 140px;
        opacity: 0;
        animation: FadeInDownUp 500ms ease-in-out 1.5s 1 normal forwards;

        img {
            width: 80%;
            display: block;
            margin: 0 auto;
        }
    }

    .main-passage {
        margin: 40px 340px;
        text-align: justify;
        opacity: 0;
        animation: FadeInDownUp 500ms ease-in-out 2s 1 normal forwards;
    }

    .bottom-writer {
        display: flex;
        margin: 30px auto;
        width: 55%;
        align-items: center;
        margin-bottom: 0px;
        padding-bottom: 100px;
        opacity: 0;
        animation: FadeInDownUp 500ms ease-in-out 2.5s 1 normal forwards;

        img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
        }


        .content {
            margin: 0px 20px;

            h4 {
                font-weight: 600;
                font-family: 'Poppins';
                color: #BBC8D4;
            }

            h3 {
                font-weight: 400;
                font-family: 'Poppins';
                margin: 10px 0px;
            }
            p {
                font-size: 16px;
                font-family: Lato;
                margin: 0px 0px 0px 0px;
                width: 200px;
                padding: 0px;
                color: #6D7D8B;
            }            
        }


    }
}


@media only screen and (max-width: 1200px) {
    .blog-page {    
        h1 {
            width: 70%;
        }
    
        .writer {

            width: 70%;
    
            img {
                width: 50px;
                height: 50px;
            }
    
            p {
                font-size: 16px;
                padding: 0px;
            }
        }
    
        .main-image {
            margin: 0 60px;
    
            img {
                width: 100%;
            }
        }
    
        .main-passage {
            margin: 40px 160px;
        }
    
        .bottom-writer {

            width: 70%;

        }
    }
}

@media only screen and (max-width: 992px) {
    .blog-page {    
        h1 {
            width: 75%;
        }
    
        .writer {

            width: 70%;
    
            img {
                width: 50px;
                height: 50px;
            }
    
            p {
                font-size: 16px;
                padding: 0px;
            }
        }
    
        .main-image {
            margin: 0 50px;
    
            img {
                width: 100%;
            }
        }
    
        .main-passage {
            margin: 40px 100px;
        }
    
        .bottom-writer {

            width: 80%;

        }
    }
}

@media only screen and (max-width: 768px) {
    .blog-page {    
        h1 {
            width: 80%;
        }
    
        .writer {

            width: 85%;
    
            img {
                width: 50px;
                height: 50px;
            }
    
            p {
                font-size: 16px;
                padding: 0px;
            }
        }
    
        .main-image {
            margin: 0 50px;
    
            img {
                width: 100%;
            }
        }
    
        .main-passage {
            font-size: 16px;
            margin: 40px 50px;
        }
    
        .bottom-writer {

            width: 80%;

        }
    }
}

@media only screen and (max-width: 576px) {
    .blog-page {    
        h1 {
            width: 100%;
            text-align: start;
            padding-left: 30px ;
            padding-right: 30px;
            padding-top: 200px;
            font-size: 28px;
        }
    
        .writer {
            display: none;
        }
    
        .main-image {
            margin: 40px 10px 0px 10px;
    
            img {
                width: 100%;
            }
        }
    
        .main-passage {
            font-size: 16px;
            margin: 0px 30px;
        }
    
        .bottom-writer {
            padding-top: 0px;
            margin-top: 0px;
            width: 80%;

        }
    }
}