@import '../App.scss';

@keyframes moveImageSlowly {
    0% {
        transform: translateX(0) scale(1)
    }
    50% {
        
        transform: translateX(-250px) scale(1.5);
    }
    100% {
        transform: translateX(0)  scale(1)
    }
}

@keyframes TurnBlue {
    0% {
        color: #000000;
    }

    100% {
        color: #4C6FFC;
    }
}

@keyframes CardSlideIn{
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.about-page {
    .hero-section {
        width: 100%;
        min-height: 700px;
        padding: 0px;
        background-image: url(../Images/about-us-page/the-team.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        z-index: 1;
        animation: PartialFadeIn 500ms ease-in-out 0.2s 1 normal forwards;
        opacity: 0.5;
    
        .hero-content {
            padding: 0px 140px;
            color: #FFFFFF;
            padding-top: 160px;
            width: 68%;
    
     
            h1 {
                width: 90%;
                margin: 20px 0px;
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.4s 1 normal forwards;

                span {
                 color: #ED4622;
                }
            }
    
            .description {
                width: 80%;
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 0.8s 1 normal forwards;
            }

            .contact-box {

                button {
                    margin-left: 0px;
                    outline: 1px solid;
                    outline-color: rgba(255, 255, 255, .5);
                    outline-offset: 0px;
                    text-shadow: none;
                    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0), 0 2px 20px 4px #ffffff44;;
                    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
                    opacity: 0;
                    animation: RegularFadeIn 500ms ease-in-out 1.2s 1 normal forwards;

                    &:hover {
                        box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2), 0 2px 20px 4px #ffffff44;
                        outline-color: rgba(255, 255, 255, 0);
                        outline-offset: 15px;
                        text-shadow: 1px 1px 2px #427388; 
                      }
                }
            }
    
    
        }

        .waving-hand {
            position: absolute;
            right: 100px;
            color: #FFFFFF;
            font-size: 20px;
            font-family: "Poppins";
            opacity: 0;
            animation: FadeInDownUp 500ms ease-in-out 2s 1 normal forwards;


            .hand {
                width: 50px;
                animation: wave 1.9s 0.6s infinite;
                transform: rotate(0deg);

             
            }
        }

        .wave {
            position: relative;
            display: block;
            transform: translateY(5px);
            z-index: 1;

        }
    }

    .mission-section {
        padding: 0px 120px 20px 120px;
        display: flex;
        align-items: center;
        background-color: #FFFFFF;
        
        .image-moving {
            width: 50%;
            align-self: stretch;
            background: url(../Images/about-us-page/mission-background.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 20px;
            display: flex;
            align-items: center;
            gap: 30px;
        


            .window-column  {
                width: 45%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 70%;
                gap: 30px;

            }

            .window {
                overflow: hidden;
                width: 100%;
                height: 35%;
                border-radius: 5px;
                background-color: #4C6FFC;
                box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, .2);
                
                .image-wrapper {
                    width: 115%;

                    img {
                        object-fit: cover;
                        width: 200%;
                        height: 200%;
                        animation: moveImageSlowly 100s infinite ease-in-out ;
                    }
                }
            }

            #second-column {
                transform: translateY(-50px);
            }
        }

        .content {
            margin: 20px 0px 0px 60px;
            width: 50%;
            
            h1 {
                color: #121E52;
            }

            span {
                color: #ED4622;
            }
        }

        &.animate-mission {
            .image-moving {
            
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 1.3s 1 normal forwards;
                
            }
    
            .content {
                h1 {
                    opacity: 0;
                    animation: FadeInDownUp 500ms ease-in-out 0.4s 1 normal forwards;
                }

                p {
                    opacity: 0;
                    animation: RegularFadeIn 500ms ease-in-out 0.8s 1 normal forwards;
                }
            }
        }
    }

    .culture-section {
        padding: 120px 140px;
        background-color: #F6F6F6;


        h1 {
            text-align: center;
            width: 80%;
            margin: 0 auto;
            color: #121E52;

        }
        p {
            text-align: center;
            width: 80%;
            margin: 0 auto;
        }

        img {
            width: 100%;
        }

        &.animate-culture {
            h1 {
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.3s 1 normal forwards;
            }

            p {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 0.6s 1 normal forwards;
            }

            img {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 0.9s 1 normal forwards;
            }
        }
    }
    
    .quote-section {
        display: flex;
        padding: 140px 140px;
        position: relative;
        background-color: #FFFFFF;

        .ceo-image {
            width: 45%;
        }

        .quote {
            position: absolute;
            width: 720px;
            right: 220px;
            top: 200px;
            
            h2 {
                line-height: 160%;
                font-size: 32px;
            }
            
            .quote-image {
                position: absolute;
                width: 60px;
            }

            .quote-up {
                top: -50px;
                left: -90px;
            }

            
            .quote-down {
                bottom: -50px;
                right: -50px;
            }
        }

        &.animate-quote {

            .ceo-image {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 1s 1 normal forwards;
            }

            .quote {

                .typewriter {
                    color:#0000;
                    background:
                    linear-gradient(-90deg,#000000 5px,#0000 0) 10px 0,
                    linear-gradient(#000000 0 0) 0 0;
                    background-size:calc(var(--n)*1ch) 200%;
                    -webkit-background-clip:padding-box,text;
                    background-clip:padding-box,text;
                    background-repeat:no-repeat;
                    animation: 
                    b .7s infinite steps(1),   
                    t calc(var(--n)*.03s) steps(var(--n)) forwards; 

                    span {
                        animation: TurnBlue 500ms ease-in-out 2.5s 1 normal forwards;
                    }
                }
                
                .quote-image {
                    opacity: 0;
                    animation: RegularFadeIn 500ms ease-in-out 1.3s 1 normal forwards;
                }

            }
        }

    }
    
    .shareholders {
        margin: 0px 0px 0px 0px;
        padding: 100px 0px;
        background: url(../Images/about-us-page/article-background.jpg);
        // background-color: white;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        

        h1 {
            margin: 0px 140px;
            text-align: center;
            color: #121E52;

        }

        p {
            margin: 0px 140px;
            text-align: center;
        }

        .card-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 50px;

            .stake-holder-image {
                width: 300px;
                height: auto;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

            }

            .card {
                display: flex;
                flex-direction: column;
                background-color: white;
                border-radius: 8px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;;

                .fields {
                    display: flex;
                    flex-direction: column;
                    padding: 1rem;
                }
                    
                .stakeholder-name {
                    font-family: 'Poppins';
                    padding: 0px;
                    margin: 0px;
                    font-size: 20px
                }

                .stakeholder-field {
                    padding: 0px;
                    margin: 0px;
                    text-align: left;

                }
                .education {
                    font-size: 14px;
                    color: #44403c;
                }
            }

        }

        &.animate-shareholders {
            .stake-holder-image {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 1s 1 normal forwards;
            }
        }
    }

    .news {
        margin: 0px 0px 0px 0px;
        padding: 100px 0px;
        // background: url(../Images/about-us-page/article-background.jpg);
        background-color: white;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        

        h1 {
            margin: 0px 140px;
            text-align: center;
            color: #121E52;

        }
        p {
            margin: 0px 140px;
            text-align: center;
        }
        
        .card-wrapper {
            width: 100%;
            overflow: auto;
            padding: 20px 200px 20px 20px;
            margin-left: 5%;
            margin-right: 5%;
            &::-webkit-scrollbar {
                display: none;
            }
        }


        .card-container {
            display: flex;
            gap: 40px;
            width: fit-content;
            justify-content: center;
            flex-wrap: wrap;
            align-content: space-between;
            .card {
                position: relative; 
                height: 300px;
                width: 400px;
                background-color: #ED4622;
                border-radius: 5px;
                box-shadow: 0px 0px 20px 0px rgb(0,0,0,0.2);
                
                .overlay {
                    background-color: rgb(0,0,0,0.3);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    transition: all 300ms ease;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }

                .title {
                    font-size: 16px ;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    margin: 40px;
                    padding: 10px 20px;
                    border-radius: 2px;
                    color: #FFFFFF;
                    background-color: rgb(0,0,0,0.5);
                }

                &:hover {
                    .overlay {
                        background-color: rgba(0, 0, 0, 0.2);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
        }

        &.animate-news {
            h1 {
                opacity: 0;
                animation: FadeInDownUp 500ms ease-in-out 0.2s 1 normal forwards;
            }
            p {
                opacity: 0;
                animation: RegularFadeIn 500ms ease-in-out 1s 1 normal forwards;
            }

            .card-container {


                .card {
                    opacity: 0;
                    animation: CardSlideIn 500ms ease-in-out 2s 1 normal forwards;
    
                    .title {
                        opacity: 0;
                        animation: FadeInDownUp 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 3s 1 normal forwards;
                    }


                }
                #card-0 {
                    animation-delay: 2s;
                }
                #card-1 {
                    animation-delay: 2.3s;
                }
                #card-2 {
                    animation-delay: 2.6s;
                }
                #card-3 {
                    animation-delay: 2.9s;
                }
                #card-4 {
                    animation-delay: 3.2s;
                }
                #card-5 {
                    animation-delay: 3.5s;
                }
            }
        }
    }
}

@media only screen and (max-width: 1410px) {
    .about-page {
        
        .quote-section {
    

    
            .quote {
                width: 650px;
                right: 130px;
                top: 150px;
                
                h2 {
                    line-height: 160%;
                    font-size: 32px;
                }
                
                .quote-image {
                    width: 50px;
                }
    
                .quote-up {
                    top: -50px;
                    left: -90px;
                }
    
                
                .quote-down {
                    bottom: -50px;
                    right: -50px;
                }
            }
    
    
        }
    
    }
}



@media only screen and (max-width: 1200px) {
    .about-page {
        .hero-section {
            width: 100%;

            .hero-content {
                padding: 160px 60px;
                width: 80%;
    
                h1 {
                    width: 90%;
                }
        
                .description {
                    width: 80%;  
                }        
            }
    
    
            .wave {
                transform: translateY(5px); 
            }
        }
    
        .mission-section {
            padding: 20px 60px 20px 60px;
            
            .image-moving {
                width: 50%;
                align-self: stretch;
                padding: 20px;
                gap: 30px;
            
    
    
                .window-column  {
                    width: 45%;
                    height: 70%;
                    gap: 30px;
    
                }
    
                .window {
                    width: 100%;
                    height: 35%;
                    
                    .image-wrapper {
                        width: 125%;
    
                        img {
                            width: 200%;
                            height: 200%;
                        }
                    }
                }
    
                #second-column {
                    transform: translateY(-50px);
                }
            }
    
            .content {
                margin: 20px 0px 0px 50px;
            }
    

        }
    
        .culture-section {
            padding: 120px 60px;
    
            h1 {
                width: 80%;
            }
            p {
                width: 80%;
            }
    
            img {
                width: 100%;
            }
    

        }
        
        .quote-section {
            padding: 100px 60px;
    
            .quote {
                width: 600px;
                right: 150px;
                top: 150px;
                
                h2 {
                    line-height: 160%;
                    font-size: 26px;
                }
                
                .quote-image {
                    width: 40px;
                }
    
                .quote-up {
                    top: -50px;
                    left: -60px;
                }
    
                
                .quote-down {
                    bottom: -50px;
                    right: -20px;
                }
            }
    
    
        }
    
        .news {
            margin: 0px 0px 0px 0px;
            padding: 100px 0px;
            background: none;
            background-color: #F6F6F6;
    
            h1 {
                margin: 0px 60px;

    
            }
            p {
                margin: 0px 60px;
   
            }
            
            .card-wrapper {
                width: 100%;
                margin-left: 60px;
                padding: 20px 200px 20px 20px;
            }
    
    
            .card-container {
            
                .card {
                    height: 300px;
                    width: 400px;

                    
                    .overlay {
                        width: 100%;
                        height: 100%;

                    }

                    .title {
                        font-size: 18px ;
                    }
    

                }
            }
    

        }
    }
}

@media only screen and (max-width: 1080px) {

    .about-page {
        .quote-section {
            padding: 100px 60px;
    
            .quote {
                width: 600px;
                right: 120px;
                top: 120px;
                
                h2 {
                    line-height: 160%;
                    font-size: 24px;
                }
                
                .quote-image {
                    width: 40px;
                }
    
                .quote-up {
                    top: -50px;
                    left: -60px;
                }
    
                
                .quote-down {
                    bottom: -50px;
                    right: -20px;
                }
            }
    
    
        }
    }
} 

@media only screen and (max-width: 992px) {
    .about-page {
        .hero-section {
            width: 100%;

            .hero-content {
                padding: 160px 50px;
                width: 80%;
    
                h1 {
                    width: 90%;
                }
        
                .description {
                    width: 80%;  
                }        
            }
    
    
            .wave {
                transform: translateY(5px); 
            }
        }
    
        .mission-section {
            padding: 20px 50px 20px 50px;
            
            .image-moving {
                width: 50%;
                align-self: stretch;
                padding: 20px;
                gap: 30px;
            
    
    
                .window-column  {
                    width: 45%;
                    height: 70%;
                    gap: 30px;
    
                }
    
                .window {
                    width: 100%;
                    height: 35%;
                    
                    .image-wrapper {
                        width: 145%;
    
                        img {
                            width: 200%;
                            height: 200%;
                        }
                    }
                }
    
                #second-column {
                    transform: translateY(-50px);
                }
            }
    
            .content {
                margin: 20px 0px 0px 50px;

                h1 {
                    font-size: 32px;
                    line-height: 130%;
                }

                p {
                    font-size: 14px;
                }
            }
    

        }
    
        .culture-section {
            padding: 120px 30px;
    
            h1 {
                width: 85%;
            }
            p {
                width: 85%;
            }
    
            img {
                width: 100%;
            }
    

        }
        
        .quote-section {
            padding: 100px 50px;
    
            .quote {
                width: 500px;
                right: 120px;
                top: 100px;
                
                h2 {
                    line-height: 160%;
                    font-size: 20px;
                }
                
                .quote-image {
                    width: 30px;
                }
    
                .quote-up {
                    top: -30px;
                    left: -60px;
                }
    
                
                .quote-down {
                    bottom: -30px;
                    right: -20px;
                }
            }
    
    
        }
        
        .shareholders {
            .card-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 50px;
    
                .stake-holder-image {
                    width: 300px;
                    height: auto;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }
    
                .card {
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    border-radius: 8px;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;;
    
                    .fields {
                        display: flex;
                        flex-direction: column;
                        padding: 1rem;
                    }
    
                    .stakeholder-name {
                        padding: 0px;
                        margin: 0px;
                        font-size: 1.5rem
                    }
    
                    .stakeholder-field {
                        padding: 0px;
                        margin: 0px;
                        text-align: left;
    
                    }
                    .education {
                        font-size: 14px;
                        color: #44403c;
                    }
                }
    
            }
        }
    
        .news {
            margin: 0px 0px 0px 0px;
            padding: 100px 0px;
            background: none;
            background-color: #F6F6F6;
    
            h1 {
                margin: 0px 50px;

    
            }
            p {
                margin: 0px 50px;
   
            }
            
            .card-wrapper {
                width: 100%;
                margin-left: 50px;
                padding: 20px 200px 20px 20px;
            }
    
    
            .card-container {
            
                .card {
                    height: 250px;
                    width: 350px;

                    
                    .overlay {
                        width: 100%;
                        height: 100%;

                    }

                    .title {
                        font-size: 18px ;
                    }
    

                }
            }
    

        }
    }
}


@media only screen and (max-width: 880px) {
    .about-page {
        .quote-section {
            padding: 100px 50px;
    
            .quote {
                width: 450px;
                right: 80px;
                top: 100px;
                
                h2 {
                    line-height: 160%;
                    font-size: 20px;
                }
                
                .quote-image {
                    width: 30px;
                }
    
                .quote-up {
                    top: -30px;
                    left: -60px;
                }
    
                
                .quote-down {
                    bottom: -30px;
                    right: -20px;
                }
            }
    
        }

    }
}


@media only screen and (max-width: 768px) {
    .about-page {
        .hero-section {
            width: 100%;

            .hero-content {
                padding: 160px 30px;
                width: 80%;
    
                h1 {
                    width: 90%;
                }
        
                .description {
                    width: 80%;  
                }        
            }

            .waving-hand {
                display: none;
            }
    
    
            .wave {
                transform: translateY(5px); 
            }
        }
    
        .mission-section {
            padding: 20px 30px 20px 30px;
            display: block;
            
            .image-moving {
                display: none;
                width: 50%;
                align-self: stretch;
                padding: 20px;
                gap: 30px;
            
    
    
                .window-column  {
                    width: 45%;
                    height: 70%;
                    gap: 30px;
    
                }
    
                .window {
                    width: 100%;
                    height: 35%;
                    
                    .image-wrapper {
                        width: 115%;
    
                        img {
                            width: 200%;
                            height: 200%;
                        }
                    }
                }
    
                #second-column {
                    transform: translateY(-50px);
                }
            }
    
            .content {
                margin: 20px 0px;
                width: 100%;
                text-align: center;


                h1 {
                    font-size: 32px;
                }

                p {
                    font-size: 14px;
                }
            }
    

        }
    
        .culture-section {
            padding: 120px 30px;
    
            h1 {
                width: 90%;
            }
            p {
                width: 90%;
            }
    
            img {
                width: 100%;
            }
    

        }
        
        .quote-section {
            padding: 100px 30px;
    
            
            .quote {
                width: 350px;
                right: 80px;
                top: 120px;
                
                h2 {
                    line-height: 160%;
                    font-size: 20px;
                }
                
                .quote-image {
                    width: 30px;
                }
    
                .quote-up {
                    top: -30px;
                    left: -60px;
                }
    
                
                .quote-down {
                    bottom: -30px;
                    right: 0px;
                }
            }
    
    
        }


    
        .news {
            margin: 0px 0px 0px 0px;
            padding: 100px 0px;
            background: none;
            background-color: #F6F6F6;
    
            h1 {
                margin: 0px 30px;

    
            }
            p {
                margin: 0px 30px;
   
            }
            
            .card-wrapper {
                width: 100%;
                margin-left: 30px;
                padding: 20px 200px 20px 20px;
            }
    
    
            .card-container {
            
                .card {
                    height: 250px;
                    width: 350px;

                    
                    .overlay {
                        width: 100%;
                        height: 100%;

                    }

                    .title {
                        font-size: 18px ;
                    }
    

                }
            }
    

        }
    }
}

@media only screen and (max-width: 660px) {
    .about-page {
        .quote-section {
            padding: 100px 30px;
    
            
            .quote {
                width: 300px;
                right: 80px;
                top: 70px;
                
                h2 {
                    line-height: 160%;
                    font-size: 20px;
                }
                
                .quote-image {
                    width: 30px;
                }
    
                .quote-up {
                    top: -30px;
                    left: -60px;
                }
    
                
                .quote-down {
                    bottom: -30px;
                    right: -20px;
                }
            }
    
    
        }
    }
}


@media only screen and (max-width: 578px) {
    .about-page {
        .hero-section {
            width: 100%;

            .hero-content {
                padding: 160px 30px;
                width: 100%;
    
                h1 {
                    width: 95%;
                }
        
                .description {
                    width: 95%;  
                }      
                
                .contact-box {
                    button {
                        margin-top: 0px;
                    }
                }
            }
    
    
            .wave {
                transform: translateY(30px); 
            }
        }
    
        .mission-section {
            padding: 20px 30px 20px 30px;
            display: block;
            
            
            .content {
                margin: 20px 0px;
                width: 100%;
                text-align: center;


                h1 {
                    font-size: 32px;
                }

                p {
                    font-size: 14px;
                }
            }
    

        }
    
        .culture-section {
            padding: 120px 30px;
    
            h1 {
                width: 100%;
            }
            p {
                width: 100%;
            }
    
            img {
                width: 100%;
            }
    

        }
        
        .quote-section {
            padding: 100px 30px;
            height: 700px;

            .ceo-image {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                top: 300px;
                width: 400px;

            }
            
            .quote {
                left: 50%;
                transform: translate(-50%, 0);
                width: 60%;
                top: 70px;
                
                h2 {
                    line-height: 160%;
                    font-size: 20px;
                }
                
                .quote-image {
                    width: 30px;
                }
    
                .quote-up {
                    top: -30px;
                    left: -40px;
                }
    
                
                .quote-down {
                    bottom: -30px;
                    right: -20px;
                }
            }
    
    
        }
        
    
        .news {
            margin: 0px 0px 0px 0px;
            padding: 100px 0px;
            background: none;
            background-color: #F6F6F6;
    
            h1 {
                margin: 0px 30px;
                font-size: 26px;
    
            }
            p {
                margin: 0px 30px;
   
            }
            
            .card-wrapper {
                width: 100%;
                margin-left: 30px;
                padding: 20px 200px 20px 20px;
            }
    
    
            .card-container {
                
                gap: 10px;

                .card {
                    height: 200px;
                    width: 300px;

                    
                    .overlay {
                        width: 100%;
                        height: 100%;

                    }

                    .title {
                        font-size: 18px ;
                    }
    

                }
            }
    

        }
    }
}

@media only screen and (max-width: 480px) {
    .about-page {
        .hero-section {    
            .wave {
                transform: translateY(20px);
            }

        }
        
        
        .quote {
            left: 50%;
            transform: translate(-50%, 0);
            width: 80%;
            top: 70px;
            
            h2 {
                line-height: 160%;
                font-size: 20px;
            }
            
            .quote-image {
                width: 20px;
            }

            .quote-up {
                top: -30px;
                left: -40px;
            }

            
            .quote-down {
                bottom: -30px;
                right: -20px;
            }
        }
    }
}



@media only screen and (max-width: 480px) {
    .about-page {
        .hero-section {    
            .wave {
                transform: translateY(25px);
            }

        }
    }
}

