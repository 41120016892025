@keyframes FadeDown {
    from {
        opacity: 0;
        transform: translateY(-40px);
    }
    to {
        transform: translateY(0px);
        opacity: 1;

    }
}

@keyframes FadeInDownUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes FadeInUpDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes RegularFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes PartialFadeIn {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

@keyframes FadeExpand {
    from {
        opacity: 0;
        width: 10%;
    }
    to {
        opacity: 1;
        width: 50%;
    }
}

@keyframes StarFall {
    0% {
        transform: rotate(315deg) translateX(0);
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        transform: rotate(315deg) translateX(-1000px);
        opacity: 0;
    }
}

@keyframes card106 {
    to {
        transform: translateX(106%);
    }
}

@keyframes card212 {
    to {
        transform: translateX(212%);
    }
}

@keyframes Rotate3D {
    to {
        transform: rotate(0);
    }
}

@keyframes Expand {
    to {
        width: 100%;
    
    }
}

@keyframes SlideIn {
    to {
        transform: translateX(0px);
    }
}


@keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(16deg);
    }
    20% {
      transform: rotate(-6deg);
    }
    30% {
      transform: rotate(16deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(16deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
}

@keyframes t{
    from {background-size:0 200%}
  }
@keyframes b{
50% {background-position:0 -100%,0 0}
}