@import '../App.scss';
@import '../Animations.scss';

.navigation-bar {
    position: absolute;
    width: 100vw;
    padding: 43px 140px;
    z-index: 999;

    .navigator {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .company-logo {
            width: 100px;  
            cursor: pointer;
                      
            img {
                width: 100%;
                opacity: 0;

            }

            .company-logo-top {
                animation: FadeDown 500ms ease-in-out 0.8s 1 normal forwards;
            }

            .company-logo-bottom {
                transform: translateX(-20px);
                animation: SlideIn 500ms ease-in-out 1.4s 1 normal forwards, RegularFadeIn 300ms ease-in-out 1.4s 1 normal forwards;
            }
        }

        .link-wrapper {
            display: flex;
            align-items: center;
            gap: 32px;

            .hamburger-menu {
                display: none;
                
                .rect {
                    width: 25px;
                    height: 3px;
                    background-color: #FFFFFF;
                    margin: 5px 0px;
                }
            }

            .navigation-list {
                display: flex;
                gap: 31px;

                .close-button {
                    display: none;
                }

                .navigation-item {
                    list-style: none;
                    opacity: 0;
                    animation: FadeDown 500ms ease-in-out 1s 1 normal forwards;

                    .navigation-link {
                        color: #ffffffb9;
                        text-decoration: none;
                        font-family: 'Poppins';
                        font-size: 16px;
                        font-weight: 400;
                        transition: all ease 400ms;


                        &:hover {
                            color: #FFFFFF;
                        }
                    }
                    
                }

                .nav-one {
                    animation-duration: 700ms;
                }

                .nav-two {
                    animation-duration: 900ms;
                }

                .nav-three {
                    animation-duration: 1000ms;
                }

                .nav-four {
                    animation-duration: 1100ms;
                }

                .nav-five {
                    animation-duration: 1150ms;
                }
            }

            .senstrio-button {
                font-size: 14px;
                font-family: 'Poppins';
                font-weight: 400;
                background-color: transparent;
                color: #ffffffb9;
                border: 2px solid #ffffffb9;
                padding: 10px 22px;
                border-radius: 4px;
                transition: all ease 400ms;
                opacity: 0;
                animation: FadeDown 700ms ease-in-out 1.6s 1 normal forwards;

                &:hover {
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;

                }
                
            }               
        }


    
    }

}

.navigation-bar.no-background {
    background-color: rgb(0, 0, 0, 0.87);
}


@media only screen and (max-width: 1200px) {
    .navigation-bar {
        padding: 43px 60px;
    
        .navigator {
    
            .company-logo {
                width: 90px;
            }
    
            .link-wrapper {

                .hamburger-menu {
                    display: none;
                    
                    .rect {
                        width: 25px;
                        height: 3px;
                        margin: 5px;
                    }
                }
    
                .navigation-list {
                    gap: 25px;
                    .navigation-item {    
                        .navigation-link {
                            font-size: 14px;
                        }
                    }
                }
    
                .senstrio-button {
                    font-size: 12px;
                    
                }               
            }
    
    
        
        }
    
    }
}

@media only screen and (max-width: 992px) {
    .navigation-bar {
        padding: 43px 50px;
    
        .navigator {
            .link-wrapper {

                .hamburger-menu {
                    display: block;
                }
    
                .navigation-list {
                    position: fixed;
                    background-color: rgb(0, 0, 0, 0.8);
                    width: 100vw;
                    height: 100vh;
                    left: 0;
                    top: 0;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 70px;
                    display: none;

                    
                    .close-button {
                        font-size: 30px;
                        font-family: 'Poppins';
                        color: #FFFFFF;
                        position: absolute;
                        top: 40px;
                        left: 40px;
                        display: block;
                    }

                    .navigation-item {    
                        color:#FFFFFF;
                        animation: FadeDown 400ms ease-in-out 0s 1 normal forwards;

                        .navigation-link {
                            font-size: 20px;
                        }
                    }
                }

                .navigation-list.active {
                    display: flex;
                }
    
                .senstrio-button {
                    display: none;
                }               
            }
    
    
        
        }
    
    }
}


@media only screen and (max-width: 768px) {
    .navigation-bar {
        padding: 43px 30px;
    
        .navigator {
            .link-wrapper {

                .hamburger-menu {
                    display: block;
                }
    
                .navigation-list {
                    position: fixed;
                    width: 100%;
                    height: 100vh;
                    left: 0;
                    top: 0;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 50px;
                    display: none;
                    
                    .close-button {
                        font-size: 30px;
                        font-family: 'Poppins';
                        position: absolute;
                        top: 40px;
                        left: 40px;
                    }

                    .navigation-item {    

                        .navigation-link {
                        }
                    }
                }

                .navigation-list.active {
                    display: flex;
                }
    
                .senstrio-button {
                    display: none;
                }               
            }
            
        }
    
    }
}